import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// User
import UserManagmentlist from "../pages/UserManagement/UserManagmentlist";
import CreateUser from "../pages/UserManagement/CreateUser";
import Updateuserdata from "../pages/UserManagement/Updateuserdata";
import Userdetails from "../pages/UserManagement/Userdetails.js";


// Activity
import ActivityList from "../pages/Activity/ActivityList";
import CreateActivity from "../pages/Activity/CreateActivity";
import UpdateActivity from "../pages/Activity/UpdateActivity";

// Voucher
import VoucherList from "../pages/VoucherManagement/VoucherList";
import CreateVoucher from "../pages/VoucherManagement/CreateVoucher";
import UpdateVoucher from "../pages/VoucherManagement/UpdateVoucher";


// Page
import PageManagementlist from "../pages/AppManagement/PageManagementlist";
import CreatePage from "../pages/AppManagement/CreatePage";
import UpdatePage from "../pages/AppManagement/UpdatePage";


// Team
import TeamManagmentlist from "../pages/TeamManagement/TeamManagmentlist";
import Teamdetails from "../pages/TeamManagement/Teamdetails.js";


// Challenge
import PickupManagmentlist from "../pages/PickupManagement/PickupManagmentlist";
import PickupDetails from "../pages/PickupManagement/Pickupdetails";

// Swap
import ChallengeManagmentlist from "../pages/ChallengeManagement/ChallengeManagmentlist";


// HomeBlock Size
import BlockSizeList from "../pages/HomeBlockSize/BlockSizeList";
import CreateBlockSize from "../pages/HomeBlockSize/CreateBlockSize";
import UpdateBlockSize from "../pages/HomeBlockSize/UpdateBlockSize";

// HomeBlock
import HomeBlockManagementlist from "../pages/HomeBlockManagement/HomeBlockManagmentlist";
import CreateHomeBlock from "../pages/HomeBlockManagement/CreateHomeBlock";
import UpdateHomeBlock from "../pages/HomeBlockManagement/UpdateHomeBlock";

// SearchBlock
import SearchBlockManagementlist from "../pages/SearchBlockManagement/SearchBlockManagmentlist";
import CreateSearchBlock from "../pages/SearchBlockManagement/CreateSearchBlock";
import UpdateSearchBlock from "../pages/SearchBlockManagement/UpdateSearchBlock";

// Banner
import HomeBannerlist from "../pages/HomeBlockBannerManagement/HomeBannerlist";
import CreateHomeBanner from "../pages/HomeBlockBannerManagement/CreateHomeBanner";
import UpdateHomeBanner from "../pages/HomeBlockBannerManagement/UpdateHomeBanner";

// Video
import VideoManagementlist from "../pages/VideoManagement/VideoManagmentlist";
import CreateVideo from "../pages/VideoManagement/CreateVideo";
import UpdateVideo from "../pages/VideoManagement/UpdateVideo";

// Story 
import StoryManagmentlist from "../pages/StoryManagement/StoryManagmentlist";
import CreateStory from "../pages/StoryManagement/CreateStory";


// Notification
import NotificationList from "../pages/Notification/NotificationList";
import UpdateNotification from "../pages/Notification/UpdateNotification";
import CreateNotification from "../pages/Notification/CreateNotification";
import Notificationdetails from "../pages/Notification/NotificationDetails";
import CloneNotification from "../pages/Notification/CloneNotification";
	




//Utility
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

const authProtectedRoutes =  [
	// Dashboard
	{ path: "/dashboard", component: <Dashboard/> },
	{ path: "/", component: <Dashboard/> },
	// Chart List
	// { path: "/chart", component: <ChartComponets/> },
	// Graph page
	// { path: "/graph", component: Graph },
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/login" /> },

	// User 
	{ path: "/users", component: <UserManagmentlist/> },
	{ path: "/usercreate", component: <CreateUser/> },
	{ path: "/updateuserdata/:id", component: <Updateuserdata/> },
	{ path: "/userdetails/:id", component: <Userdetails/> },
	

	// Activity	
	{ path: "/activity", component: <ActivityList /> },	
	{ path: "/createactivity", component: <CreateActivity /> },  
	{ path: "/updateactivity/:id", component: <UpdateActivity /> },

	// Voucher
     { path: "/vouchers", component: <VoucherList /> },
	 { path: "/createvoucher", component: <CreateVoucher /> },
	 { path: "/updatevoucher/:id", component: <UpdateVoucher /> },


	
	// Page		
	{ path: "/pages", component: <PageManagementlist/> },		
	{ path: "/createPage", component: <CreatePage/> },	
	{ path: "/updatePage/:id", component: <UpdatePage/> },

	// Team 
	{ path: "/teams", component: <TeamManagmentlist/> },	
	{ path: "/teamdetails/:id", component: <Teamdetails/>},

	// Challenge
	{ path: "/pickups", component: <PickupManagmentlist/> },
	{ path: "/pickupdetails/:id", component: <PickupDetails/>},

	// Swap
	{ path: "/challenges", component: <ChallengeManagmentlist/> },

	// BlockSize List
	{ path: "/blocksize", component: <BlockSizeList /> },
	{ path: "/Createsizedata", component: <CreateBlockSize /> },
	{ path: "/Updatesizedata/:id", component: <UpdateBlockSize /> },

	// HomeBlock 
	{ path: "/homeblock", component: <HomeBlockManagementlist /> },	
	{ path: "/createHomeblock", component: <CreateHomeBlock /> },	
	{ path: "/updateHomeblock/:id", component: <UpdateHomeBlock /> },		
	{ path: "/homeBlockPreview", component: <homeBlockPreview /> },

	// SearchBlock
    { path: "/searchblock", component: <SearchBlockManagementlist />},
	{ path: "/createSearchblock", component: <CreateSearchBlock />},
	{ path: "/updateSearchblock/:id", component: <UpdateSearchBlock />},



	// HomeBanner	  
	  { path: "/banner", component: <HomeBannerlist/> },	  
	  { path: "/createbanner", component: <CreateHomeBanner/> },	  
	  { path: "/updatebanner/:id", component: <UpdateHomeBanner/> },

	// videos
	{ path: "/videos", component: <VideoManagementlist/> },	
	{ path: "/createVideo", component: <CreateVideo/> },	
	{ path: "/updateVideo/:id", component: <UpdateVideo />},

	// Story	
	{ path: "/stories", component: <StoryManagmentlist/> },
	{ path: "/createStory", component: <CreateStory />},
	

	
	// Notification
	{ path: "/notification", component: <NotificationList /> },
	{ path: "/notificationdetails/:id", component: <Notificationdetails /> },
	{ path: "/notificationclone", component: <CloneNotification /> },
	{ path: "/Createnotification", component: <CreateNotification /> },
	{ path: "/Updatenotificationdata/:id", component: <UpdateNotification /> },
  ];

const publicRoutes = [
	{ path: "/logout", component: <Logout /> },
	{ path: "/login", component: <Login /> },
	{ path: "/forgot-password", component: <ForgetPwd /> },
	{ path: "/register", component: <Register /> },
	{ path: "/lock-screen", component: <AuthLockScreen /> },

	// Authentication Inner	

	{ path: "/maintenance", component: <Maintenance /> },
	{ path: "/comingsoon", component: <CommingSoon /> },
	{ path: "/404", component: <Error404 /> },
	{ path: "/500", component: <Error500 /> },
];

export { authProtectedRoutes, publicRoutes };
