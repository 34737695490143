import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { checkLogin, apiError } from '../../store/actions';
import logodark from "../../assets/images/pickup-logo2.png";
import logolight from "../../assets/images/pickup-logo2.png";
import logoImage from "../../assets/images/LoginImage.png";
import withRouter from '../../components/Common/withRouter';

import { postRequest } from "../../components/Common/Utils";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from 'react-router-dom';



const Login = ({ checkLogin, apiError, router }) => {
    let history = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");


    async function handleSubmit() {
        let data = {email: username , password };
        postRequest("user/admin/login", data).then((data) => {
          let log = data;
          console.log(log); 
         
          if (log.status) {
            toast.success(log.message);
            localStorage.setItem("authUser", JSON.stringify(log.data.admin));
            localStorage.setItem("access", "admin");
            localStorage.setItem("token", log.data.token);
            localStorage.setItem("acc_type", log.data.type);
            localStorage.setItem("stored_bussinessId", log.data?.admin?._id);
    
    
            console.log(log.data.type)
            
    
            if(log.data.type === "business"){         
              setTimeout(() => {
                history("/business-account/dashboard");
              }, 1000);    
              return;
            }            
           
            setTimeout(() => {
              history("/dashboard");
            }, 1000);
          } else {
            toast.error(log.message);
          }
        });
      }

    useEffect(() => {
        apiError("");
        document.body.classList.add("auth-body-bg");

        return () => {
            document.body.classList.remove("auth-body-bg");
        };
    }, []);
     

    return (
    <React.Fragment>
        <ToastContainer />
            <div>
            <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="">
                                                            <img src={logodark} alt="" height="50" className="auth-logo logo-dark mx-auto" />
                                                            <img src={logolight} alt="" height="50" className="auth-logo logo-light mx-auto" />
                                                        </Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                                    <p className="text-muted">Sign in to continue to Pickup.</p>
                                                </div>                                                
                                                <div className="p-2 mt-5">
                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="username">Email</Label>
                                                            <AvField name="username" value={username} onChange={(e) => setUsername(e.target.value)}  type="text" className="form-control" id="username" validate={{ email: true, required: true }} placeholder="Enter username" />
                                                        </div>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">Password</Label>
                                                            <AvField name="password" value={password} onChange={(e) => setPassword(e.target.value)}  validate={{  required: true }}type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                                        </div>
{/* 
                                                        <div className="form-check">
                                                            <Input type="checkbox" className="form-check-input" id="customControlInline" />
                                                            <Label className="form-check-label" htmlFor="customControlInline">Remember me</Label>
                                                        </div> */}

                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">                                                   
                                                    <p>© 2024 Pickup. </p>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay">
                                <img src={logoImage} alt="" height="1300" className="auth-logo logo-dark mx-auto" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>           
            </div>
    </React.Fragment>
    )
};

const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
};

export default withRouter(connect(mapStatetoProps, { checkLogin, apiError })(Login));
