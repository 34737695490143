import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  CardHeader,
  Button,
  Form,
  Modal,
  Table,
  Spinner,
  Container,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import {
  deleteRequest,
  postRequest,
  pushUpdates,
  putRequest,
} from "../../components/Common/Utils.js";
import Select from "react-select";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import noImage from "../../assets/images/No_Image_Available.jpg";

// import OfferModal from "../../components/Modal/OfferModal.js";
import ActivityModal from "../../components/Modal/ActivityModal.js";
import BannerModal from "../../components/Modal/BannerModal.js";

import TeamModal from "../../components/Modal/TeamModal.js";
import VideoModal from "../../components/Modal/VideoModal.js";
// import Countdown from "./CountDown.js";
// import ActivityModal from "../../components/Modal/ActivityModalId.js";
// import EventModal from "../../components/Modal/EventModalId.js";
import Lightbox from "react-image-lightbox";
import DragDropModal from "./DragDropModal.js";
// import VoucherModal from "../../components/Modal/VoucherModal.js";
// import VideoModal from "../../components/Modal/VideoModal.js";

export default function UpdateSerachBlock() {
  const location = useLocation();
  const state = location.state?.row;
  const [customDataVenue, setDataVenue] = useState([]);
  const [customDataOffer, setDataOffer] = useState([]);
  const [customDataDeal, setDataDeal] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [customDataComponent, setDataComponent] = useState([]);
  const [venueData, setVenueData] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const [coverLogo, setCoverLogo] = useState([]);
  const [offerListDetail, setOfferListDetail] = useState([]);
  const [venueListDetail, setVenueListDetail] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const [bannerModal, setBannerModal] = useState(false);  
  const [bannerText, setBannerText] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [modelDeal, setModelDeal] = useState(false);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [modelActivity, setModelActivity] = useState(false);
  const [modelTeam, setModelTeam] = useState(false);
  const [modelBanner, setModelBanner] = useState(false);
  const [modelEvent, setModelEvent] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [dragDropData, setDragDropData] = useState([]);


  const [defaultCategory, setDefaultCategory] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [vouchars, setVouchars] = useState([]);

  const [homeData, setHomeBlockData] = useState({});
  const [loading, setLoading] = useState(false);
  const [homeId, setSelectedId] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [categoryoptions, setcategoryOptions] = useState([]);

  const [venueList, setVenueList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [idName, setIdName] = useState("venueId");
  const [typecustom, setTypeCustom] = useState("");

  const [venueoptions, setvenueOptions] = useState([]);
  const [offeroptions, setofferOptions] = useState([]);

  const [sizeList, setSizeList] = useState([]);
  const [model, setModel] = useState(false);
  const [model2, setModel2] = useState(false);

  const [selectedVideo, setSelectedVideo] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [selectedSlider, setSelectedSlider] = useState([]);

  const [defaultVenue, setDefaultVenue] = useState([]);
  const [defaultVideo, setDefaultVideo] = useState([]);
  const [defaultComponent, setDefaultComponent] = useState([]);
  const [defaultOffer, setDefaultOffer] = useState([]);

  const [venueLoaded, setVenueLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [customCompLoaded, setCustomCompLoaded] = useState(false);
  const [offerLoaded, setOfferLoaded] = useState(false);
  const [modelcustom, setModelCustom] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [editVenueIndex, setVenueIndex] = useState(null);
  const [editOfferIndex, setOfferIndex] = useState(null);
  const [addVenue, setVenueAdd] = useState(null);
  const [typeCustomvalue, setTypeEdit] = useState([]);

  const [dealList, setDealList] = useState([]);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [defaultDeals, setDefaultDeals] = useState([]);
  const [dealLoaded, setDealLoaded] = useState(false);

  const [lowestDiscount, setLowestDiscount] = useState("");
  const [editIndex, setEditIndex] = useState(null);

  const [activityPreview, setActivityPreview] = useState([]);
  const [teamPreview, setTeamPreview] = useState([]);
  const [bannerPreview, setBannerPreview] = useState([]);
  const [eventPreview, setEventPreview] = useState([]);
  const [activityId, setActivityId] = useState([]);
  const [teamId, setTeamId] = useState([]);
  const [bannerId, setBannerId] = useState("");
  const [eventId, setEventId] = useState([]);
  const [modelVoucher, setModelVoucher] = useState(false);

  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);

  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [defaultTheme, setDefaultTheme] = useState([]);
  const [defaultMusic, setDefaultMusic] = useState([]);
  const [defaultFeature, setDefaultFeature] = useState([]);
  const [defaultCuisine, setDefaultCuisine] = useState([]);
  const [uploadedVideo, setUploadedVideo] = useState([]);
  const [videoMdl, setVideoMdl] = useState(false);

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [eventLoaded, setEventLoaded] = useState(false);

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    if (!homeData?.stateLoaded) return;
    // HomeBlockFetchDetail();
  }, [homeData?.stateLoaded]);

  useEffect(() => {
    console.log("uploadedVide updated", selectedDeals);
    console.log("uploadedVide updated", uploadedVideo);
  }, [uploadedVideo, selectedDeals]);

  const AddUploadedVideo = (video) => {
    setUploadedVideo([...uploadedVideo, video]);
  };

  const closeVideoModal = () => {
    setVideoMdl(false);
  };

 

  const msUpdateTheme = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTheme(ids);
  };

  const msUpdateMusic = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedMusic(ids);
  };

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedFeature(ids);
  };

  const msUpdateCuisine = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCuisine(ids);
  };




 
  const pushSelectedDeal = (deal) => {
    const isExist = selectedDeals.find((x) => x._id === deal._id);

    if (isExist) {
      toast.error("Deal already added");
      return;
    }
    const selectedDealsCopy = [...selectedDeals];
    selectedDealsCopy.push(deal);
    setSelectedDeals(selectedDealsCopy);
    closeModalVoucher();
  };

  const popSelectedDeal = (index) => {
    const selectedDealsCopy = [...selectedDeals];
    selectedDealsCopy.splice(index, 1);
    setSelectedDeals(selectedDealsCopy);
  };

  const [formValues, setFormValues] = useState([]);
  const [currentForm, setCurrentForm] = useState({
    title: "",
    subTitle: "",
    description: "",
    info: "",
    badge: "",
    typeselect: "",
  });

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const msUpdateVenue = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedVenue(ids);
  };

  const msUpdateOffer = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedOffer(ids);
  };

  let history = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
   
   
    fetchSizeList();
    
  }, []);

  useEffect(() => {
    if (!defaultDeals?.length) return;
    voucherManagment(defaultDeals);
  }, [defaultDeals]);

  const voucherManagment = async (deals) => {
    const payload = {
      ids: deals,
    };

    const response = await postRequest(`homeblock/deal-package/list`, payload);
    const data = response.data;
    let dealList = []
    deals.map(i => {
      let dealData = data.list?.find(deal => deal._id == i)
      if (dealData) dealList.push(dealData)
    })
    setSelectedDeals(dealList);
  };
  const msUpdateCategory = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);
  };

  useEffect(() => {
    if (!selectedCategory?.length) return;
    fetchCategoryList2();
  }, [selectedCategory]);

  const fetchCategoryList2 = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("venue/category/list/admin", param);
    if (category.status == 1) {
      const options = category.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCategoryList(options);
      if (selectedCategory?.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCategory.includes(item.id);
        });
        setDefaultCategory(defaultItems);
      }
    }
  };

  useEffect(() => {
    if (!state) return;

    setSelectedVenue(state.venues);
    setSelectedComponent(state.customComponents);
    setSelectedId(state._id);
    setSelectedVideo(state.videos);
    setSelectedOffer(state.offers);
    setDefaultDeals(state.deals);
    setSelectedEvents(state.events);
    setSelectedActivity(state.activities);
    setSelectedTeam(state.teams);
    //setSelectedBanner(state.banners);

    // setBannerId(state.bannerData?._id ? state.bannerData._id : "");
    // setBannerText(state.bannerData?.title ? state.bannerData.title : "");
    // setBannerImage(state.bannerData?.items?.length ?  state.bannerData.items?.[0].image : "");
    

 
    setSelectedTheme(
      state?.venueFilterForAuto?.themes ? state?.venueFilterForAuto?.themes : []
    );
    setSelectedMusic(
      state?.venueFilterForAuto?.musics ? state?.venueFilterForAuto?.musics : []
    );
    setSelectedFeature(
      state?.venueFilterForAuto?.features
        ? state?.venueFilterForAuto?.features
        : []
    );
    setSelectedCuisine(
      state?.venueFilterForAuto?.cuisines
        ? state?.venueFilterForAuto?.cuisines
        : []
    );

    const defaultItems = state?.categoryForOffer ? state?.categoryForOffer : [];

    setSelectedCategory([...defaultItems]);

    console.log("state-isauto", state?.isAuto);

    if (state?.isAuto === true) {
      state.isAuto = "1";
    } else {
      state.isAuto = "0";
    }

    // console.log("state", state.showTitle)

    if (state?.showTitle === false) {
      state.showTitle = false;
    } else {
      state.showTitle = true;
    }

    if(state.type == "activity"){
      const activities = state.activityData.map((item) => {
        return {                     
          title: item.title,
          icon: item.icon,
          logo: item.icon,
          address: item.title,
          cover: item.icon,
        };
      });
      setActivityPreview(activities);          
      setActivityId(state.activities);
    }else if(state.type == "team"){
      const teams = state.teamData?.map((item) => {
        return {                     
          title: item.name,
          icon: item.image,
          description: item.bio,
          logo: item.image,
          cover: item.image,
          address: item.title,
          userlogo: item.ownerData?.image,
          address : `${item?.ownerData?.first_name} ${item?.ownerData?.last_name}`,
          label : item?.level
         
        };
      }

      );

      setTeamPreview(teams);
      setTeamId(state.teams);
    }else if(state.type == "banner" || state.type == "create_challenge"){

      setBannerId(state.bannerData?._id ? state.bannerData._id : "");
      setBannerText(state.bannerData?.title ? state.bannerData.title : "");
      setBannerImage(state.bannerData?.items?.length ?  state.bannerData.items?.[0].image : "");
     
    } 

    state.stateLoaded = true;
    setHomeBlockData({ ...state });
    setUploadedVideo([...state?.videos]);
    console.log("state", state);
  }, [state]);

 

  const fetchSizeList = async () => {
    setLoading(true);
    const response = await postRequest("home-block/size/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          type: item.type,
          ratio: item.ratio,
        };
      });
      setSizeList(list);
    }
    setLoading(false);
  };

  useEffect(() => {
    // need to calculate lowest discount discount is string so we need to convert it to number

    var lowest = 100000;
    if (vouchars.length > 0) {
      lowest = vouchars.reduce((prev, curr) => {
        return parseFloat(prev.discountValue) < parseFloat(curr.discountValue)
          ? prev
          : curr;
      });
    }
    setLowestDiscount(lowest ? lowest.discountValue : "");
  }, [vouchars]);

  const addVoucher = () => {
    setVouchars([...vouchars, { saved: false }]);
    setEditIndex(vouchars.length);
  };

 

  const updateCategory = (e) => {
    setCurrentForm((prevForm) => ({
      ...prevForm,
      category: e.value,
    }));
  };



  const fetchVideoList = async () => {
    setLoading(true);
    const response = await postRequest("home-block/video/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setVideoList(list);

      const defaultItems = list.filter((item) => {
        return selectedVideo.includes(item.id);
      });
      setDefaultVideo(defaultItems);
    }

    setVideoLoaded(true);

    setLoading(false);
  };

  const toggleModelVoucher = () => {
    setModelVoucher(!modelVoucher);
  };

  const closeModalVoucher = () => {
    setModelVoucher(false);
  };



  const fetchEventList = async () => {
    setLoading(true);
    const response = await postRequest("event/list", { limit: 10000000 });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });

      const defaultItems = response.data.list.filter((item) => {
        return selectedEvents.includes(item?._id?.toString());
      });

      defaultItems.forEach((element) => {
        let selectRow = element;

        let arr = [],
          cover,
          logo,
          address,
          label,
          title,
          discount,
          description;
        title = selectRow.title;
        description = selectRow?.description;
        cover = selectRow?.image;
        logo = selectRow?.orgLogo;
        address = selectRow?.orgAddress;
        discount = selectRow?.pack?.discountValue;

        label = selectRow?.orgName;

        arr = { cover, label, logo, address, title, description, discount };
        // setEventPreview([...eventPreview, arr]);
        // setEventId([...eventId, selectRow._id]);
      });

      setEventLoaded(true);
    }
    setLoading(false);
  };

  const fetchComponentList = async () => {
    setLoading(true);
    const response = await postRequest("homeblock/custom/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setComponentList(list);

      const defaultItems = list.filter((item) => {
        return selectedComponent.includes(item.id);
      });
      setCustomCompLoaded(true);
      setDefaultComponent(defaultItems);
    }
    setLoading(false);
  };

  const toggleModel = () => {
    let length = venueData.length;
    setVenueIndex(length);
    setModel(!model);
  };


  const toggleModel2 = () => {
    let length = offerData.length;
    setModel2(!model2);
    setOfferIndex(length);
  };
  const EdittoggleModelVenue = (index) => {
    const setindex = index;
    setVenueIndex(setindex);
    setModel(!model);
  };
  const EdittoggleModelOffer = (index) => {
    const setindex = index;
    setOfferIndex(setindex);
    setModel2(!model2);
  };

  const closeModal2 = () => {
    setModel2(false);
  };

  const closeModal = () => {
    setModel(false);
    setModelDeal(false);
    setModelCustom(false);
  };
  const closeModal4 = () => {
    setModelEvent(false);
  };

  const saveVoucher = (index) => {
    setVouchars(
      vouchars.map((voucher, i) =>
        i === index ? { ...voucher, saved: true } : voucher
      )
    );
    setEditIndex(null);
  };

  const selectRow = (selectRow, id) => {
    let arr = [],
      cover,
      logo,
      address,
      name,
      _id;

    cover = selectRow.cover;
    _id = id;
    logo = selectRow.logo;
    address = selectRow.address;
    name = selectRow.name;
    const newArray = [...venueData];
    newArray[editVenueIndex] = { cover, logo, address, name, _id };
    setVenueData(newArray);
    closeModal();
  };
  const selectRow2 = (selectRow2, id) => {
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      description,
      _id;
    title = selectRow2.title;
    _id = id;
    description = selectRow2.description;
    cover = selectRow2.image;
    logo = selectRow2.venue.logo;
    address = selectRow2.venue.address;
    label = selectRow2.venue.name;
    arr = { cover, logo, address, label, title, description, _id };
    const newArray = [...offerData];

    newArray[editOfferIndex] = selectRow2;
    setOfferData(newArray);
    closeModal2();
  };

  const updateSerachBlock = async (e) => {
    e.preventDefault();
    console.log({ offerData });
    // if (!homeData.size) {
    //   //homeData.size = "64743d1ba021f3bf326ab3ce";

    //   toast.error("Please select video");
    //   return;

    //   return;
    // }

    console.log("homeData", homeData);

    if (homeData.type != "video" && homeData.type != "my-outing") {
      if (!homeData.title) {
        toast.error("Title is required");
        return;
      }
    }

    if (homeData.type == "venue" && !homeData.size) {
      toast.error("Please select video");
      return;
    }

    if (homeData.type == "video" && !uploadedVideo.length) {
      toast.error("Please select video");
      return;
    }
    let venueIds = [];
    if (
      homeData.type == "venue" &&
      !venueData?.length &&
      homeData?.isAuto != "1"
    ) {
      toast.error("Please select venue");
      return;
    } else {
      venueData?.map((item) => {
        venueIds.push(item._id);
      });
    }

    if (homeData.type == "activity" && !activityId.length) {
      toast.error("Please select activity");
      return;
    }

    if (
      homeData.type == "event" &&
      !eventId.length &&
      homeData?.isAuto != "1"
    ) {
      toast.error("Please select event");
      return;
    }

    // if (homeData.type == "venue" && !selectedVenue?.length) {
    //   toast.error("Please select venue");
    //   return;
    // }
    let offerIds = [];

    if (
      homeData.type == "offer" &&
      !offerData?.length &&
      homeData?.isAuto != "1"
    ) {
      toast.error("Please select offer");
      return;
    } else {
      offerData.map((item) => {
        offerIds.push(item._id);
      });
    }

    if (homeData.type == "slider" && !selectedSlider?.length) {
      toast.error("Please select slider");
      return;
    }

    if (homeData.type == "deal" && !selectedDeals.length) {
      toast.error("Please add deals");
      return;
    }

    setLoading(true);

    let uVideos = uploadedVideo?.map((video) => video._id);
    //return;

    let dealIds = selectedDeals?.map((deal) => deal._id);

    const payload = {
      ...homeData,
      id: homeId,
      offers: offerIds,
      sliders: selectedSlider,
      videos: uVideos,
      customComponents: selectedComponent,
      // activities: homeData.type == "activity" ? activityId : [],
      venues: venueIds,
      banners: homeData.type == "banner" || 'create_challenge' ? bannerId : [],
      teams: homeData.type == "team" ? teamId : [],
     
      events: homeData.type == "event" ? eventId : [],      

      // venues: homeData.isAuto == "1" ? [] : venueIds,
      categoryForOffer: selectedCategory,
      customVenues: homeData.type == "custom-venue" ? customDataVenue : [],
      customOffers: homeData.type == "custom-offer" ? customDataOffer : [],
      deals: homeData.type == "deal" ? dealIds : [],
    };

    if (homeData.type == "venue" && homeData?.isAuto == "1") {
      let currentCategoryForAuto = {
        themes: selectedTheme,
        musics: selectedMusic,
        features: selectedFeature,
        cuisines: selectedCuisine,
      };
      payload.venueFilterForAuto = currentCategoryForAuto;
    }

    // let url = "story/create";
    // if(storyData.mediaType == 'video'){
    //   url = "story/create/video";
    // }
    console.log({ payload, uVideos })
    // return false
    const response = await putRequest("home-block/search/update", payload);
    setLoading(false);
    if (response.status === 1) {
      // If the update is successful, call the pushUpdates function

      try {
        const pushUpdatesResponse = await pushUpdates({
          type: "home-block",
          id: id,
          receivers: [],
        });
      } catch (error) {
        console.log("chat event not worked");
      }

      toast.success("Search Block updated successfully");
      setTimeout(() => {
        history(-1);
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...homeData };
    list[index] = value;
    if (index == "type") {
      if (value == "custom-offer") {
        setTypeCustom("custom");
        setIdName("offerId");
        setCoverLogo([]);
      } else if (value == "custom-venue") {
        setIdName("venueId");
        setTypeCustom("custom");
        setCoverLogo([]);
      } else {
        setTypeCustom("");
      }
      if (value == "event") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "recent";
      }
      if (value == "venue") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "all";
      }
      if (value == "offer") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "all";
      }
      if (value != "venue" && value != "event" && value != "offer") {
        list["isAuto"] = "0";
        list["categoryForAuto"] = "";
      }
    }
    setHomeBlockData({ ...list });
  };

  const [numFieldsVenue, setNumFieldsVenue] = useState(1);
  const [numFieldsOffer, setNumFieldsOffer] = useState(1);
  const handleAddFieldVenue = () => {
    setNumFieldsVenue(numFieldsVenue + 1);
  };
  const handleAddFieldOffer = () => {
    setNumFieldsOffer(numFieldsOffer + 1);
  };

  const handleInputChange = (e, index, field) => {
    const newVouchers = [...vouchars];
    newVouchers[index][field] = e.target.value;
    setVouchars(newVouchers);
  };

  const selectBannerRow = (selectRow) => {
    console.log(selectRow);

    setBannerId(selectRow?._id);
    setBannerText(selectRow?.title);
    setBannerImage(selectRow?.items[0]?.image);
    closeBannerModal();

  }

  const closeBannerModal = () => {
    setBannerModal(false);
  }

  const toggleBannerModal = () => {
    setBannerModal(!bannerModal);
  }

  const handleRemoveVideo = (index) => {
    let newUploadedVideo = uploadedVideo.filter((_, idx) => idx !== index);
    setUploadedVideo(newUploadedVideo);
    // let tempUploaded = [...uploadedVideo]
    // tempUploaded = tempUploaded.filter((item) => item._id != id);
    // setUploadedVideo([...tempUploaded]);
  };

  const handleRemoveFieldVenue = (index) => {
    if (index > 0) {
      if (customDataVenue?.length > index) {
        let data = customDataVenue.slice(index);
        setDataVenue(data);
      }
      setNumFieldsVenue(numFieldsVenue - 1);
    }
    delete coverLogo[index];
  };

  const handleRemoveFieldOffer = (index) => {
    if (index > 0) {
      if (customDataOffer?.length > index) {
        let data = customDataOffer.slice(index);
        setDataOffer(data);
      }
      setNumFieldsOffer(numFieldsOffer - 1);
    }
    delete coverLogo[index];
  };

  const deleteVoucher = (index) => {
    setVouchars(vouchars.filter((_, i) => i !== index));
  };

  // const HomeBlockFetchDetail = async () => {
  //   postRequest(`home-block/detail`, { id: `${id}` }).then((data) => {
  //     if (data.data[0]?.type == "custom-venue") {
  //       let homeblockdata = data.data[0].customVenues;
  //       let homeblockdata1 = data.data[0];
  //       setDataVenue(homeblockdata);
  //       setNumFieldsVenue(homeblockdata?.length);
  //       setTypeCustom("custom");
  //       setIdName("venueId");
  //     } else if (data.data[0].type == "custom-offer") {
  //       let homeblockdata = data.data[0].customOffers;
  //       setDataOffer(homeblockdata);
  //       setNumFieldsOffer(homeblockdata?.length);
  //       setTypeCustom("custom");
  //       setIdName("offerId");
  //     } else if (data.data[0].type == "custom-components") {
  //       let homeblockdata = data.data[0].customComponents;
  //       setDataComponent(homeblockdata);
  //       setNumFieldsOffer(homeblockdata?.length);
  //       setTypeCustom("custom");
  //       setIdName("offerId");
  //     } else if (data.data[0].type == "venue") {
  //       let homeblockdata = [...venueData];
  //       const list = { ...homeData };
  //       let isAuto = data.data[0].isAuto;
  //       let venues = data.data[0].venues || [];
  //       list.isAuto = isAuto ? "1" : "0";
  //       list.type = "venue";
  //       list.promoteVenue = venues.length != 0 ? "1" : "0";
  //       setHomeBlockData(list);
  //       homeblockdata = data.data[0].venues;
  //       setVenueData(homeblockdata);
  //       setNumFieldsOffer(homeblockdata?.length);
  //       // setTypeCustom('custom');
  //       // setIdName("offerId");
  //     } else if (data.data[0].type == "activity") {
  //       // let homeblockdata = data.data[0].activities;
  //       // const ids = homeblockdata.map((item) => item._id);
  //       // homeblockdata = homeblockdata.map((item) => {
  //       //   return {
  //       //     ...item,
  //       //     activityId: item._id,
  //       //     cover: item.provider.logo,
  //       //     logo: item.provider.logo,
  //       //     address: item.provider.address,
  //       //     label: item.provider.name,
  //       //     description: item.description,
  //       //     title: item.name,
  //       //     discount: item.discount,
  //       //   };
  //       // });
  //       // setActivityPreview(homeblockdata);
  //       // setActivityId(ids);
  //       // setNumFieldsOffer(homeblockdata?.length);
  //     } else if (data.data[0].type == "event") {
  //       let homeblockdata = data.data[0].events;
  //       const list = { ...homeData };
  //       let isAuto = data.data[0].isAuto;
  //       let events = data.data[0].events || [];
  //       list.isAuto = isAuto ? "1" : "0";
  //       list.type = "event";
  //       list.promoteEvent = events.length != 0 ? "1" : "0";
  //       setHomeBlockData(list);
  //       const ids = homeblockdata.map((item) => item._id);

  //       homeblockdata = homeblockdata.map((item) => {
  //         return {
  //           ...item,
  //           eventId: item._id,
  //           cover: item.image,
  //           logo: item.orgData?.logo,
  //           address: item.orgData?.website,
  //           label: item.orgData.name,
  //           description: item.description,
  //           title: item.title,
  //           discount: "",
  //         };
  //       });
  //       console.log({ ids });
  //       setEventPreview(homeblockdata);
  //       setEventId(ids);
  //       setNumFieldsOffer(homeblockdata?.length);
  //       // setTypeCustom('custom');
  //       // setIdName("offerId");
  //     } else if (data.data[0].type == "offer") {
  //       let homeblockdata = data.data[0].offers;
  //       const list = { ...homeData };
  //       let offers = data.data[0].offers || [];
  //       list.type = "offer";
  //       list.promoteOffer = offers.length != 0 ? "1" : "0";
  //       setOfferData(homeblockdata);
  //       setHomeBlockData(list);
  //       setNumFieldsOffer(homeblockdata?.length);
  //     } else if (data.data[0].type == "deal") {
  //       let homeblockdata = data.data[0].deals;
  //       setDataDeal(homeblockdata);
  //       setNumFieldsOffer(homeblockdata?.length);
  //     } else if (data.data[0].type == "video") {
  //       let homeblockdata = data.data[0].video;
  //       let videoList = []
  //       data.data[0]?.videos?.map((id) => {
  //         let video = homeblockdata.find(i => i._id.toString() == id.toString())
  //         if (video) videoList.push(video)
  //       })
  //       setUploadedVideo(videoList);
  //       // setNumFieldsOffer(homeblockdata?.length);
  //     }
  //   });
  // };
  const handleCoverChange = (e, index) => {
    let arr = [],
      cover,
      bid,
      logo,
      address,
      label;
    arr = arr.concat(coverLogo);
    let value = e.value;
    let title = e.label;

    if (homeData?.type == "custom-venue" || homeData?.type == "deal") {
      let result = venueListDetail.find((i) => {
        if (i._id == value) {
          return i;
        }
      });
      cover = result.cover;
      logo = result.logo;
      address = result.address;
      label = title;
      bid = value;
    } else if (homeData?.type == "custom-offer") {
      let result = offerListDetail.find((i) => {
        if (i._id == value) {
          return i;
        }
      });
      cover = result.venue.cover;
      logo = result.venue.logo;
      address = result.venue.address;
      label = title;
    }

    let newObj = {
      ...currentForm,
      cover,
      logo,
      address,
      label,
      bid,
      [idName]: value,
    };
    //setCoverLogo(newObj);
    setCurrentForm(newObj);
  };

  const toggleModelCustom = () => {
    if (homeData?.type == "custom-venue") {
      let length = customDataVenue.length;
      setCurrentIndex(length);
    } else if (homeData?.type == "custom-offer") {
      let length = customDataOffer.length;
      setCurrentIndex(length);
    } else if (homeData?.type == "custom-components") {
      let length = customDataComponent.length;
      setCurrentIndex(length);
    }

    setModelCustom(!modelcustom);
  };

  const toggleModelDeal = () => {
    let length = customDataDeal.length;
    setCurrentIndex(length);
    setModelDeal(!modelDeal);
  };

  const EditModelCustom = (item, index) => {
    if (homeData?.type == "custom-venue") {
      const myObject = {
        label: item.label || item.forSelect.label,
        value: item.venueId || item.forSelect.value,
      };
      setTypeEdit(myObject);
    } else if (homeData?.type == "custom-offer") {
      const myObject = {
        label: item.label || item.forSelect.label,
        value: item.offerId || item.forSelect.value,
      };
      setTypeEdit(myObject);
    } else if (homeData?.type == "deal") {
      const myObject = {
        label: item?.label || item?.forSelect?.label,
        value: item?.dealId || item?.forSelect?.value,
      };

      setSelectedCategory({
        label: item?.category?.title,
        value: item?.categoryId,
      });

      setTypeEdit(myObject);
      // let arr = [], label,value
      // label = item.label;
      // value = item.venueId;
      // setTypeEdit([...arr])

      setCurrentIndex(index);
      setCurrentForm(item);

      setFormValues((prevValues) => [...prevValues, item]);
      setVouchars(item.vouchars);
      setModelDeal(!modelDeal);
      return;
    }

    setCurrentIndex(index);
    // let arr = [], label,value
    // label = item.label;
    // value = item.venueId;
    // setTypeEdit([...arr]);

    setCurrentForm(item);
    setFormValues((prevValues) => [...prevValues, item]);
    setModelCustom(!modelcustom);
  };
  const closeModalCustom = () => {
    setModelCustom(false);
    setCurrentForm({
      title: "",
      subTitle: "",
      description: "",
      info: "",
      badge: "",
      typeselect: "",
    });
  };

  const handleRemoveFormValue = (index, id = false) => {
    if (homeData?.type == "custom-venue") {
      const newArray = [...customDataVenue];
      newArray.splice(index, 1);
      setDataVenue(newArray);
    } else if (homeData?.type == "custom-offer") {
      const newArray = [...customDataOffer];
      newArray.splice(index, 1);
      setDataOffer(newArray);
    } else if (homeData?.type == "deal") {
      const newArray = [...customDataDeal];
      newArray.splice(index, 1);
      setDataDeal(newArray);
      if (id) {
        deleteDeal(id);
      }
    }
  };

  const deleteDeal = async (id) => {
    await deleteRequest("home-block/deal/delete", {
      dealId: id,
    });
  };

  const deleteDealVouchar = async (deal, id) => {
    await deleteRequest("home-block/deal/vouchar/delete", {
      dealId: deal,
      voucharId: id,
    });
  };

  const CustomFormValue = (event) => {
    event.preventDefault();
    if (homeData?.type == "custom-venue") {
      const newArray = [...customDataVenue];
      newArray[currentIndex] = currentForm;
      setDataVenue(newArray);
    } else if (homeData?.type == "custom-offer") {
      const newArray = [...customDataOffer];
      newArray[currentIndex] = currentForm;
      setDataOffer(newArray);
    } else if (homeData?.type == "custom-components") {
      const newArray = [...customDataComponent];
      newArray[currentIndex] = currentForm;
      setDataComponent(newArray);
    }
    setModelCustom(!modelcustom);
    setCurrentForm({
      title: "",
      subTitle: "",
      description: "",
      info: "",
      badge: "",
      typeselect: "",
    });
  };

  const CustomFormDeal = (event) => {
    event.preventDefault();
    //return;
    // setInputs('');
    const newData = {
      ...currentForm,
      title: currentForm.title,
      description: currentForm.description,
      venue: currentForm?.bid,
      category: currentForm.category,
      startDate: currentForm.startDate,
      endDate: currentForm.endDate,
      lowestDiscount: lowestDiscount,
      image: selectedImage,
    };
    newData.vouchars = vouchars;

    // get current form data
    const newArray = [...customDataDeal];
    newArray[currentIndex] = newData;
    setDataDeal(newArray);

    setCurrentForm({
      title: "",
      description: "",
      venue: "",
      startDate: "",
      endDate: "",
    });
    // reset vouchars
    setVouchars([]);
    closeModal();
  };

  const [customdata, setData] = useState([]);
  const pushData = (newData) => {
    setData((prevData) => [...prevData, newData]);
  };

  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setCurrentForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleRemovevenuPreview = (index) => {
    const newArray = [...venueData];
    newArray.splice(index, 1);
    setVenueData(newArray);
  };
  const handleRemoOfferPreview = (index) => {
    const newArray = [...offerData];
    newArray.splice(index, 1);
    setOfferData(newArray);
  };

  const toggleModelActivity = () => {
    setModelActivity(!modelActivity);
  };

  const toggleModelTeam = () => {
    setModelTeam(!modelTeam);
  };

  const toggleModelBanner = () => {
    setModelBanner(!modelBanner);
  };

  const toggleModelEvent = () => {
    setModelEvent(!modelEvent);
  };

  const closeModal3 = () => {
    setModelActivity(false);
  };
  const closeModalT = () => {
    setModelTeam(false);
  };
  const closeModalB = () => {
    setModelBanner(false);
  };

  const selectActivityRow = (id, selectRow) => {
    // check if activity is already added    

    const isActivityAlreadyAdded = activityId.includes(id);
    if (isActivityAlreadyAdded) {
      toast.error("Activity already added");
      return;
    }
    closeModal3();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      icon,
      description;
    title = selectRow.title;
    icon = selectRow.icon;
    description = selectRow?.description?.description;
    cover = icon;
    logo = icon;
    address = title;
    label = "";
    arr = { cover, logo, address, label, title, icon, description };

    console.log({ arr });


    console.log([...activityPreview, arr])

    setActivityPreview([...activityPreview, arr]);
    setActivityId([...activityId, id]);
  };

  const selectTeamRow = (id,selectRow) => {
    // check if activity is already added

    const isTeamAlreadyAdded = teamId.includes(id);

    if (isTeamAlreadyAdded) {
      toast.error("Team already added");
      return;
    }

  //   closeModalT();
  //   let arr = [],
  //     cover,
  //     logo,
  //     address,
  //     label,
  //     title,
  //     icon,
  //     description;
  //   title = selectRow.name;
  //   icon = selectRow.icon;
  //   description = selectRow?.description?.description;
  //   cover = selectRow.provider?.logo;
  //   logo = selectRow.provider?.logo;
  //   address = selectRow.provider?.address;
  //   label = selectRow.provider?.name;
  //   arr = { cover, logo, address, label, title, icon, description };
  //   setTeamPreview([...teamPreview, arr]);
  //   setTeamId([...teamId, id]);
  // };

  closeModalT();
  let arr = [],
    cover,
    logo,
    address,
    label,
    title,
    icon,
    userlogo,
    description;
  title = selectRow.name;
  icon = selectRow.image;   
  description = selectRow?.bio;
  cover = selectRow?.image;
  userlogo = selectRow?.ownerData?.image;
  logo = selectRow.image;


  address = `${selectRow?.ownerData?.first_name} ${selectRow?.ownerData?.last_name}`;
  label = selectRow?.level;

  arr = { cover, logo, address, label, title, icon, userlogo, description };

  setTeamPreview([...teamPreview, arr]);
  setTeamId([...teamId, id]);
};
console.log({ teamPreview });
console.log({ teamId });

  const selectBannRow = (selectRow, id) => {
    // check if activity is already added

    console.log({ selectRow, id });

    const isBannerAlreadyAdded = bannerId.includes(id);

    if (isBannerAlreadyAdded) {
      toast.error("Banner already added");
      return;
    }

    closeModalB();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      icon,
      description;
    title = selectRow.type;
    icon = selectRow.image;
    description = "";
    cover = icon;
    logo = icon;
    address = selectRow.title;
    label = selectRow.provider?.name;
    arr = { cover, logo, address, label, title, icon, description };
    setBannerPreview([...bannerPreview, arr]);
    setBannerId([...bannerId, id]);
  };
  const handleRemoveActivityPreview = (index) => {
    const newArray = [...activityPreview];
    newArray.splice(index, 1);
    setActivityPreview(newArray);
    const newvenue = [...activityId];
    newvenue.splice(index, 1);
    setActivityId(newvenue);
  };

  const handleRemoveTeamPreview = (index) => {
    const newArray = [...teamPreview];
    newArray.splice(index, 1);
    setTeamPreview(newArray);
    const newvenue = [...teamId];
    newvenue.splice(index, 1);
    setTeamId(newvenue);
  };
  const handleRemoveBannerPreview = (index) => {
    const newArray = [...bannerPreview];
    newArray.splice(index, 1);
    setBannerPreview(newArray);
    const newvenue = [...bannerId];
    newvenue.splice(index, 1);
    setBannerId(newvenue);
  };

  const selectEventRow = (selectRow, id) => {
    // check if activity is already added

    const isEventAlreadyAdded = eventId.includes(id);

    if (isEventAlreadyAdded) {
      toast.error("Event already added");
      return;
    }

    closeModal4();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      discount,
      description;
    title = selectRow.title;
    description = selectRow?.description;
    cover = selectRow?.image;
    logo = selectRow?.orgLogo;
    address = selectRow?.orgAddress;
    discount = selectRow?.pack?.discountValue;

    label = selectRow?.orgName;

    arr = { cover, label, logo, address, title, description, discount };
    setEventPreview([...eventPreview, arr]);
    setEventId([...eventId, id]);
  };

  const handleRemoveEventPreview = (index) => {
    const newArray = [...eventPreview];
    newArray.splice(index, 1);
    setEventPreview(newArray);
    const newvenue2 = [...eventId];
    newvenue2.splice(index, 1);
    setEventId(newvenue2);
  };

  const updateDragDropData = async (data) => {
    setDragDropData(data);
    if (homeData.type == "venue") {
      setVenueData(data);
    } else if (homeData.type == "offer") {
      setOfferData(data);
    } else if (homeData.type == "video") {
      setUploadedVideo(data);
    } else if (homeData.type == "custom-venue") {
      setDataVenue(data);
    } else if (homeData.type == "custom-offer") {
      setDataOffer(data);
    } else if (homeData.type == "event") {
      setEventPreview(data);
    } else if (homeData.type == "activity") {
      setActivityPreview(data);
    } else if (homeData.type == "team") {
      setTeamPreview(data);
    }
    else if (homeData.type == "banner") {
      setBannerPreview(data);
    }
    else if (homeData.type == "deal") {
      setSelectedDeals(data);
    }
    setShowOrder(false);
  };

  const DragDropTable = () => {
    if (homeData.type == "venue") {
      setDragDropData(venueData);
    } else if (homeData.type == "offer") {
      console.log({ offerData });
      setDragDropData(offerData);
    } else if (homeData.type == "video") {
      setDragDropData(uploadedVideo);
    } else if (homeData.type == "custom-venue") {
      setDragDropData(customDataVenue);
    } else if (homeData.type == "custom-offer") {
      setDragDropData(customDataOffer);
    } else if (homeData.type == "event") {
      setDragDropData(eventPreview);
    } else if (homeData.type == "deal") {
      setDragDropData(selectedDeals);
    } else if (homeData.type == "activity") {
      setDragDropData(activityPreview);
    }
    else if (homeData.type === "team") {
      setDragDropData(teamPreview);
    }
    else if (homeData.type === "banner") {
      setDragDropData(bannerPreview);
    }
    setShowOrder(true);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container >

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Searchblock Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Serachblock Management</li>:{" "}
              <li className="breadcrumb-item">Update Serachblock</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history(-1)}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

     
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Serach Block </h4>

                <Form onSubmit={updateSerachBlock}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              defaultValue={homeData.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Show Title in App
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              defaultChecked={homeData.showTitle}
                              onChange={(e) =>
                                updateValue("showTitle", e.target.checked)
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={homeData.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>

                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              value={homeData.type}
                              name="mediaType"
                              class="form-select"
                            >
                              <option value="">Select</option>
                              <option value="banner">Banner</option>                             
                              <option value="team">Team</option>     
                              <option value="create_team">Create Team</option>
                              <option value="create_challenge">Create Challenge</option>
                              <option value="video">Video</option>
                              <option value="invite_friend">Invite Friend</option>
                              <option value="challenge">Challenge</option>
                              <option value="my_team">My Team</option>                        
                            </select>
                          </div>
                        </div>
                    

                        {homeData?.type == "team" && (
                          <>
                            <div className="row align-items-center">
                              {teamPreview.map((form, index) => (
                                <div className="col-lg-4 mb-3"  key={index}>
                                  <div
                                    class="box-custom red-border "
                                    className={
                                      form[index]
                                        ? "box-custom bgcard"
                                        : "box-custom"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      backgroundImage: `url(${form?.icon})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                  

                                    <div class="badge-deal">                                     
                                      {form?.label ? form?.label : "Friendly"}
                                    </div>

                                    <h5
                                      className="title"
                                      style={{
                                        fontSize: "32px",
                                        textAlign: "left",
                                        marginTop: "10px",
                                        color: "#fff",
                                      }}
                                    >
                                      {form.title ? form.title : "Title"}
                                    </h5>

                                    <h4
                                      style={{
                                        fontFamily: "Montserrat",
                                        textAlign: "left",
                                        fontSize: "20px",
                                        lineHeight: "36px",
                                        color: "#fff",
                                      }}                                   
                                    >
                                      {form.description
                                        ? form.description
                                        : "Description"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="d-flex align-items-center deal-footer">
                                        <div
                                          className="logo"
                                          style={{ background: "inherit" }}
                                        >
                                          <img
                                            src={form?.userlogo || noImage}
                                            alt="logo"
                                            className="rounded-circle"
                                          />
                                        </div>

                                        <div>                                         
                                          
                                          <p style={{ fontSize: "20px" }}>
                                            {form?.address
                                              ? form?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveTeamPreview(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelTeam}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>

                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>{" "}
                                 Team Order
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                         {/* {homeData?.type == "banner" || homeData?.type == "create_challenge" && (
                          <>
                            <div className="row align-items-center">
                              {bannerPreview.map((form, index) => (
                                <div className="col-lg-4 mb-3" key={index}>
                                  <div
                                    class="box-custom red-border "
                                    className={
                                      form[index]
                                        ? "box-custom bgcard"
                                        : "box-custom"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      backgroundImage: `url(${form?.logo})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <h5
                                      className="title"
                                      style={{
                                        textAlign: "left",
                                        marginTop: "10px",
                                        color: "#fff",
                                      }}
                                    >
                                      {form.title ? form.title : "Title"}
                                    </h5>

                                    <div className="bottom-footer">
                                      <div className="d-flex align-items-center deal-footer">
                                       

                                        <div>                                                                             
                                          <p style={{ fontSize: "20px", marginLeft: "20px" }}>
                                            {form?.address
                                              ? form?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveBannerPreview(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelBanner}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>

                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>{" "}
                                 Banner Order
                                </Button>
                              </div>
                            </div>
                          </>
                        )} */}


                        {(homeData?.type == "banner" ||  homeData?.type == "create_challenge" ) && (

                                                  
                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Select Banner
                        </label>

                          <div
                          className="col-sm-6"
                          onClick={() => toggleBannerModal()}
                        >
                          
                          {bannerText ? (
                          
                            <div class="input-group">
                              <button
                                class="btn"
                                style={{
                                  border: "1px solid #ced4da",
                                  background: "#5e18c9",
                                  color: "#ffff",
                                }}
                                type="button"
                                id="chooseVenueBtn"
                                onclick={() => toggleBannerModal()}
                              >
                                Select Banner
                              </button>
                              <div
                                style={{
                                  border: "1px solid #ced4da",
                                  width: "16px",
                                }}
                              ></div>
                              <img
                                src={bannerImage}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  border: "1px solid #ced4da",
                                  borderRight: "none",
                                  borderLeft: "none",
                                }}
                              ></img>
                              <input
                                type="text"
                                style={{ borderLeft: "none" }}
                                class="form-control"
                                readonly
                                value={bannerText}
                                id="selectedVenue"
                                placeholder="No Banner chosen"
                              />
                            </div>
                          ) : (
                            
                            <div class="input-group">
                              <button
                                class="btn"
                                style={{
                                  border: "1px solid #ced4da",
                                  background: "#5e18c9",
                                  color: "#ffff",
                                }}
                                type="button"
                                id="chooseVenueBtn"
                                onclick={() => toggleBannerModal()}
                              >
                                Select Banner
                              </button>
                              <input
                                type="text"
                                class="form-control"
                                readonly
                                id="selectedVenue"
                                placeholder="No Banner chosen"
                              />
                            </div>
                          )}
                        </div>   
                        </div> 

                        )}


                        {homeData?.type == "video" && (
                          <>

                            <div className="row g-2 mb-4">
                              <label className="col-sm-3 col-form-label">
                                Add Video
                              </label>

                              <div className="col-lg-9 video-list">
                                <div className="row">
                                  {uploadedVideo?.map((item, index) => (
                                    <div
                                      className="col-lg-4 g-3 card-video"
                                      key={item?._id || index}
                                    >
                                      <video width="100%" controls>
                                        <source
                                          src={item?.videoUrl || item?.video}
                                          type="video/mp4"
                                        />
                                      </video>
                                      {item.title}
                                      <div style={{ textAlign: "start" }}>
                                        <button
                                          type="button"
                                          onClick={() => handleRemoveVideo(index)}
                                          className="btn-remove"
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                </div>

                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    //  onClick={CustomVideoModal}
                                    onClick={() => setVideoMdl(true)}
                                    className="modal-custom-button mt-3 mb-3"
                                  >
                                    <i class="fas fa-plus"></i>
                                  </button>

                                  <Button
                                    onClick={DragDropTable}
                                    className="btn btn-info"
                                    style={{ marginLeft: "1rem" }}
                                  >
                                    <i className="fas fa-grip-vertical"></i>  Video Order
                                  </Button>
                                </div>
                              </div>

                            </div>
                          </>
                        )}

                        {homeData?.type == "venue" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Venue Selection
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue("isAuto", e.target.value)
                                }
                                class="form-select"
                                value={homeData?.isAuto || "0"}
                              >
                                <option value="1">Automatic</option>
                                <option value="0">Manual</option>
                              </select>
                            </div>
                          </div>
                        )}                   


                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
    </div>


      {/* modal */}

  
      <TeamModal
        show={modelTeam}
        closeModal={closeModalT}
        selectRow={selectTeamRow}
      />
         <BannerModal
        show={bannerModal}
        closeModal={ () => setBannerModal(false)}
        selectRow={selectBannerRow}
      />
    


      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

       <VideoModal
        show={videoMdl}
        AddUploadedVideo={AddUploadedVideo}
        closeModal={closeVideoModal}
      />



      <DragDropModal
        show={showOrder}
        closeModal={() => setShowOrder(false)}
        data={dragDropData}
        setData={updateDragDropData}
      />
    </React.Fragment>
  );
}
