 
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

import Toggle from "react-toggle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-toggle/style.css";
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css'; // optional for styling

import FilterModal from "../../components/Modal/FilterModal";
import {  
  putRequest,
  postRequest,
  deleteRequest,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";


import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomAvatar from "../../components/Common/Avatar.js";

const filterObj = {
  status: "",	
  createdAt: "", 
};

const SwapManagementlist = ({userData, challengeUserId}) => {
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [userdata, setuserdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");  

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  useEffect(() => {
    userManagment();
  }, [currentPage]);

  const userManagmentSearch = async () => {
    if(currentPage == 1){
      userManagment();
      return;
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    if (currentPage == 1) {
      userManagment();
      return;
    }
    setCurrentPage(1);
  }, [sort, sortOrder, sizePerPageOrg]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value === true ? false : true;
    
    const dataObj = {
      userId: actId,
      status: status,
    };

    const res = await putRequest("team/update/admin", dataObj);

    if (res.status == 1) {
      toast.success("Team Status updated Successfully");
      userManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

 

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);        
      }
    }    
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {

    if(key === "phone"){
      // valid phone +1 123-456-7890 apply regex
      if(value.includes("-")){     
        return;
      } 
    }
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const fetchFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (
          key === "name" ||
          key === "activityName" ||
          key === "venue" 
        ) {
          filterObject.type = "regex";
        } else if (key === "status" || key === "matchLevel") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });   
    return filterArgs
  }  

  const applyFilter = () => {
    setFilterActive(true)    
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (
          key === "name" ||
          key === "activityName" ||
          key === "venue" 
        ) {
          filterObject.type = "regex";
        } else if (key === "status" || key === "matchLevel") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });    
    toggleModel();    

    userManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilterActive(false)
    setFilter({ ...filterObj });
    toggleModel();
    userManagment(null, true);
  };
  

  
  function dateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.createdAt)}</div>;
  }

  function ImgFormatter(cellContent, row) {
    return (
      <>
        {row?.activityData?.icon && (
          <img
            src={row?.activityData?.icon}
            onClick={() => showLightBox(row?.activityData?.icon)}
            alt="img"
            width={"50px"}
          />
        ) }
      </>
    );
  }
    

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("status", e.target.value)}
          className="form-select"
          value={filter.status}
        >
          <option value="">Select Status</option>
          <option value="pending">Pending</option>
          <option value="rejected">Rejected</option>
          <option value="matched">Matched</option>
        </select>
      </div>
    </div>,

   

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  function playerOneFormatter(cellContent, row) {
    return (
      <div style={{}}>
        <p>
        {row?.playerOneData?.image ? (
          <img
            src={row?.playerOneData?.image}
            onClick={() => showLightBox(row?.playerOneData?.image)}
            alt="img"
            width={"50px"}
          />
        ) : (
          <CustomAvatar
            name={
              row?.playerOneData?.name === ""
                ? "*"
                : `${row?.playerOneData?.name}`
            }
          />
        )}&nbsp;&nbsp;
        {row?.playerOneData?.name}</p>
      </div>
    );
  }

  function playerTwoFormatter(cellContent, row) {
    return (
      <div style={{}}>
        <p>
        {row?.playerTwodata?.image ? (
          <img
            src={row?.playerTwodata?.image}
            onClick={() => showLightBox(row?.playerTwodata?.image)}
            alt="img"
            width={"50px"}
          />
        ) : (
          <CustomAvatar
            name={
              row?.playerTwodata?.name === ""
                ? "*"
                : `${row?.playerTwodata?.name}`
            }
          />
        )}&nbsp;&nbsp;
        {row?.playerTwodata?.name}</p>
      </div>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "playerOneType",
      text: "Player One Type",
      sort: false,
    },
    {
      dataField: "playerTwoType",
      text: "Player Two Type",
      sort: false,
    },
    {
      dataField: "playerOneData.name",
      text: "Player One Name",
      formatter: playerOneFormatter,
      sort: false,
    },
    {
      dataField: "playerTwodata.name",
      text: "Player Two Name",
      formatter: playerTwoFormatter,
      sort: false,
    },   

    {
      dataField: "status",
      text: "Status",
      sort: false,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    }

  ];

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };

  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");
  };

  useEffect(() => {  
    if(!resetSearch) return;
    if (currentPage == 1) {
      userManagment([], true);
      return;
    }
    setCurrentPage(1);    
  }, [resetSearch]);

  const handleNavigate = row => {
    navigate(`/updateuserdata/${row._id}`, { state: { row } });
  };

 

  const userManagment = async (filterArgs = [], resetFilter = false) => {    
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
      teamId: challengeUserId,

    };
    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }
    if(filterActive && !filterArgs?.length && !resetFilter){
      payload.filterArgs = fetchFilter()
    }
    try {
      const response = await postRequest(`activity/swapping/list/all`, payload);
      const data = response.data;
      if(resetSearch){
        setResetSearch(false);
      }
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setuserdata(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };
 
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
   

          <Row>
            
           
            <Col className="col-12">
              <Card>
                {loader ? (
                  <CardBody style={{ height: "100px" }}>
                    <Spinner
                      color="info"
                      style={{
                        height: "4rem",
                        width: "4rem",
                        position: "absolute",
                        left: "50%",
                      }}
                    >
                      Loading...
                    </Spinner>
                  </CardBody>
                ) : (
                  <CardBody className="table-shorting">
                    {
                      <PaginationProvider
                        pagination={paginationFactory({
                          page: currentFPage,
                          sizePerPage: sizePerPageOrg,
                          totalSize: totalPage,
                          custom: true,
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <>
                            <ToolkitProvider
                              keyField="_id"
                              data={userdata}
                              columns={columns}
                            >
                              {(props) => (
                             
                           <div className='row px-3 g-3'>
                               <div className="col-6 col-md-2 d-flex align-items-center">
                                    <label className="mb-0 text-label me-2">
                                      Page Size
                                    </label>

                                    <select
                                      value={sizePerPageOrg}
                                      onChange={handleChange}
                                      className="form-select"
                                      // style={{ width: "10%" }}
                                    >
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                    </select>
                                    </div>

                                    <div className="col-6 col-md-2 d-flex align-items-center">
                                    <label className="mb-0 text-label me-2">
                                      Sort By:
                                    </label>
                                    <select
                                      value={sort}
                                      onChange={handlesorting}
                                      className="form-select"
                                      // style={{ width: "25%" }}
                                    >
                                     
                                      <option value="status">Status</option>                                                                    
                                      <option value="createdAt">Created At</option>
                                    </select>
                                    </div>

                                    <div className="col-12 col-md-2 d-flex align-items-center">
                                    <label className="mb-0 text-label ms-2">
                                      Sort Order:
                                    </label>
                                    <select
                                      value={sortOrder}
                                      onChange={handlesortOrder}
                                      className="form-select"
                                      // style={{ width: "20%" }}
                                    >
                                      <option value="asc"> Ascending</option>
                                      <option value="desc">Descending</option>
                                    </select>
                                    </div>
                                    <div className="col-12  offset-md-1 col-md-3  d-flex align-items-center  justify-content-start justify-content-md-end">
                                     
                                      <input
                                        type="search"
                                        placeholder="Search"
                                        value={searchText}
                                        class="form-control me-2"
                                        onChange={(e) =>
                                          setSearchText(e.target.value)
                                        }
                                        // style={{ width: "30%" }}
                                      />
                                      <button
                                        onClick={() => {
                                          userManagmentSearch();
                                        }}
                                        type="button"
                                        className="btn btn-primary me-2"
                                      >
                                        Search
                                      </button>
                                    </div>
                                      <div className="col-12 flex-wrap col-md-2 d-flex align-items-center">
                                      {selectedItems.length > 0 && (
                                        <button
                                          className="btn btn-danger me-2"
                                          onClick={() => {
                                            handlerdeleteMultiple(selectedItems);
                                          }}
                                        >
                                          Delete Selected
                                        </button>
                                      )}  
                                      <button
                                        type="button"
                                        onClick={handleReload}
                                        className="btn btn-danger me-2"
                                      >
                                     
                                    
                                        Reset
                                      </button>
                                      <button
                                        type="button"
                                        onClick={toggleModel}
                                        className="btn btn-info"
                                      >
                                        Filter
                                      </button>
                                    
                                    </div>
                                  

                                  <Col className="col-lg-12 mt-3 text-end">
                                    <BootstrapTable
                                      onTableChange={handleTableChange}
                                      noDataIndication={() => <div>No data available</div>}
                                      remote={true}                                      
                                      rowClasses={rowClasses}
                                      classes="table-custom-border"
                                      bootstrap4
                                      {...props.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </Col>
                            </div>
                            
                              )}
                            </ToolkitProvider>

                            <Row>
                              <Col className="col-lg-12 text-end">
                                <div className="text-md-right">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </PaginationProvider>
                    }
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>

       

       

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default SwapManagementlist;
