import React , {useEffect} from "react"

import { CountUp } from 'countup.js';

import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  CardBody,
  Card,
  Container
} from "reactstrap"

import { 
  postRequest
  
} from "../../components/Common/Utils";

// Pages Components

import EmailSent from "./email-sent";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import UserAnalytics from "./UserAnalytics";
import TeamAnalytics from "./TeamAnalytics";
import ChallengeAnalytics from "./ChallengeAnalytics";
import RegistrationByLocations from "./RegistrationByLocations";
import OnliUserByLoctions from "./OnliUserByLoctions";

import DashCard from "./DashCard";
//Import Action to copy breadcrumb items from local state to redux state
// import { setBreadcrumbItems } from "../../store/actions";


const temporaryData = {
  currentMonth: [
    { date: "2024-05-01", value: 100 },
    { date: "2024-05-02", value: 150 },
    { date: "2024-05-03", value: 200 },
    // Add more daily data as needed
  ],
  prevMonth: [
    { date: "2024-04-01", value: 80 },
    { date: "2024-04-02", value: 120 },
    { date: "2024-04-03", value: 160 },
    // Add more daily data as needed
  ],
  currentYear: [
    { month: "January", value: 2000 },
    { month: "February", value: 2500 },
    { month: "March", value: 3000 },
    // Add more monthly data as needed
  ],
  prevYear: [
    { month: "January", value: 1800 },
    { month: "February", value: 2200 },
    { month: "March", value: 2700 },
    // Add more monthly data as needed
  ],
  thisMonthTotal: 4500,
  prevMonthTotal: 3600,
  thisYearTotal: 30000,
  prevYearTotal: 29000
};


const Dashboard = (props) => {
   
  const navigate = useNavigate();   

    const [dashCardStat, setDashCardStat] = React.useState([]);

    const [action, setAction] = React.useState("");

    const [salesData, setSalesData] = React.useState([]);
    const [topVenueData, setVenueData] = React.useState([]);
    const [topOfferData, setOfferData] = React.useState([]);
    const [topSubscription, setSubsData] = React.useState([]);

    const [countryWiseData, setCountryWiseData] = React.useState([]);
    const [ calendarEvents, setCalendarEvents ] = React.useState([]);
    const [pieChartData, setPieChartData] = React.useState({});

    const [claimedAmount, setClaimedAmount] = React.useState(null);
    const [commisionEarned, setCommisionEarned] = React.useState(null);
    const [analyticsData, setAnalyticsData] = React.useState(null);  
    const [teamAnalyticData, setTeamAnalyticData] = React.useState(null);
    const [challengeAnalyticData, setChallengeAnalyticData] = React.useState(null);

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  // useEffect(() => {
  //   props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
  // },)

  const DashboardStat = async () => {    
    postRequest(`user/admin/dashboard-data`, {}).then((data) => {
      let Counts = data?.data;  
      setDashCardStat(Counts.dashCard);
      let tempSales = [...salesData];
      tempSales = Counts.salesData;
      setSalesData(tempSales);
      setCountryWiseData(Counts.countryWiseData);
      setCalendarEvents(Counts.calendarData);
      setPieChartData(Counts.pieChartData);


      let tempClaimedAmount = {...claimedAmount};
      tempClaimedAmount = Counts.claimedAmount;

      //console.log('tempClaimedAmount', tempClaimedAmount);
      setClaimedAmount(tempClaimedAmount)

      let tempCommissionAmount = {...commisionEarned};
      tempCommissionAmount = Counts.commisionEarned;
   
      setCommisionEarned(tempCommissionAmount);
      let tempStat = {...analyticsData};
      tempStat = Counts.logstat;
      setAnalyticsData(tempStat);  

      let tempTeamStat = {...teamAnalyticData};
      tempTeamStat = Counts.teamLogstat;
      setTeamAnalyticData(tempTeamStat);

      let tempChallengeStat = {...challengeAnalyticData};
      tempChallengeStat = Counts.challangeLogstat;
      setChallengeAnalyticData(tempChallengeStat);
      
      
    });
  };

  useEffect(() => {
    DashboardStat();
  }, [action]);  

 
  return (
    <React.Fragment>
 <div className="page-content">

<Container fluid>
    
<Row>
<Col className='12'>
            <div className="page-title-box">
              <h4>Dashboard </h4>
             
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Dashboard</li>
                : <li className="breadcrumb-item">Dashboard Counter</li>
              </ol>
            </div>
          </Col>       


<Row>

        <DashCard values={dashCardStat} />

        <Col xl="12">          
          <OnliUserByLoctions />
        </Col>        

  <Col xl="12">          
          <EmailSent />
        </Col> 



</Row>  

      <Row>
                            <Col xl={12}>
                               
                                <Row>       

                                    { analyticsData && (
                                      <UserAnalytics data={analyticsData} />
                                    ) }                        

                                  

                                </Row>

                               

                               

                            </Col>       

                            
                     
                        </Row>

                        <Row>
                            <Col xl={12}>
                               
                                <Row>       

                                    { teamAnalyticData && (
                                      <TeamAnalytics data={teamAnalyticData} />
                                    ) }                        

                                 

                                </Row>

                               

                               

                            </Col>       

                            
                     
                        </Row>
                        <Row>
                            <Col xl={12}>
                               
                                <Row>       

                                    { challengeAnalyticData && (
                                      <ChallengeAnalytics data={challengeAnalyticData} />
                                    ) }                        

                                  

                                </Row>

                               

                               

                            </Col>       

                            
                     
                        </Row>
                        
                        <Row>                         

                            <Col xl={12}>
                              <RegistrationByLocations countryData={countryWiseData} setAction={setAction}/>
                            </Col>

                            
                        </Row> 
        

      </Row>
     

      

      </Container>
        </div>
     
    </React.Fragment>
  )
}

export default connect(null, {  })(Dashboard);
