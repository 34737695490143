import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner,Container } from "reactstrap";
import CustomAvatar from "../../components/Common/Avatar.js";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
} from "../../components/Common/Utils";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import "./CustomCard.css";
import { DetailsOutlined, Visibility } from "@material-ui/icons";
import MemberListTable from "./MemberlistTable";
import PickupListTable from "./PickuplistTable";
import ChallengeListTable from "./ChallengelistTable";
import PickupRequestListTable from "./PickupRequestlistTable";

const allTabs = [
  "Member List", "Pickup List", "Pickup Request", "Challenge List"];

  const getActiveQ = () => {
    const search = location.search;
    return new URLSearchParams(search).get("active");
  };


export default function Teamdetails() {  
  const [activeQ, setActiveQ] = useState(getActiveQ() || "Member List");
  const [teamData, setData] = useState([]);  
  const [loader, setLoader] = useState(true);
  const [openLightbox, setOpenLightBox] = React.useState(false);  
  const [visibleTabs, setVisibleTabs] = useState(allTabs.slice(0, 9));
  const [dropdownTabs, setDropdownTabs] = useState(allTabs.slice(9));

  const [showDropdown, setShowDropdown] = useState(false);
  
  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

    const handleTabClick = (tab) => {
    setActiveQ(tab);

    if (dropdownTabs.includes(tab)) {
      const newVisibleTabs = [...visibleTabs];
      const newDropdownTabs = [...dropdownTabs];
      const removedTab = newVisibleTabs.pop();
      newVisibleTabs.push(tab);

      const tabIndex = newDropdownTabs.indexOf(tab);
      newDropdownTabs.splice(tabIndex, 1);
      newDropdownTabs.push(removedTab);

      setVisibleTabs(newVisibleTabs);
      setDropdownTabs(newDropdownTabs);
    }

    setShowDropdown(false);
  };


  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#5e18c9", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    detailIcon: {
      color: "blue",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
  });

  const classes = useStyles();

  const { id } = useParams();


  const TeamFetchDetail = async () => {
    setLoader(false);
    postRequest(`team/ids`, { teamIds: [id] }).then((data) => {
      let teamdata = data.data[0];
      let status = data.status;
      setData(teamdata);

      if (status == 1) {
        setLoader(false);
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    TeamFetchDetail();
  }, []);

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

 
  const {
    name,
    userName,   
    bio,
    level,
    image,  
    ownerData,
    createdAt,
  
  } = teamData || {};

  console.log(teamData)

  const initials =
    teamData?.first_name?.charAt(0).toUpperCase() +
    teamData?.last_name?.charAt(0).toUpperCase();
  return (
    <>
      <React.Fragment>
      <div className="page-content">

      <Container fluid> 
        <Row>
          <Col className="10">
            <div className="page-title-box">
              <h4>Team Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Team Management</li>:
                <li className="breadcrumb-item">Team details</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.back()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>

          {loader ? (
            <CardBody style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "4rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </CardBody>
          ) : (
            <>
              <Col md="12">
                <Row>
                  <Card className="custom-card">
                    <div
                      className="cover-image"
                      style={{ backgroundColor: "#1d9bf0" }}
                    ></div>
                    <div className="profile-image">
                      {teamData?.image ? (
                        <img
                          src={image}
                          onClick={() => showLightBox(image)}
                          alt="User Avatar"
                        />
                      ) : (
                        
                        <CustomAvatar
                          iconSize="74"
                          name={
                            userName == ""
                              ? "*"
                              : `${userName} `
                          }
                        />
                      )}
                    </div>

                    <div className="row my-2 py-2" style={{padding: "16px"}}>
                      <div
                        className="userinfo col-md-12 "
                        style={{ paddingLeft: "20px" }}
                      >
                        <br></br><br></br>

                        <div className="fst">
                          <h3>  
                                                      
                            {name}
                          </h3>
                        </div>

                        <div className="row">
                          <div className="col-md-4 fst py-1">
                            <p>
                              Bio: <span> {bio}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Level: <span> {level}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Owner: <span> {ownerData?.name}</span>
                            </p>
                          </div>
                     
                          <div className="col-md-4 fst py-1">
                            <p>
                              Created At: <span> {formatDate(createdAt)}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Row>
              </Col>
              <Col md="12" style={{marginLeft:"inherit"}}>
                {/* <div
                  id="exTab2"
                  className="twitter-like-tabs2"
                  style={{ background: "#ffff", marginLeft: "25px" }}
                >
                  <ul
                    className="nav nav-pills"
                    style={{ width: "480px", display: "contents" }}
                  >
                    <li className="active">
                      <a href="#memberList" data-toggle="tab">
                        Member List
                      </a>
                    </li>

                    <li>
                      <a href="#pickups" data-toggle="tab">
                        Pickup List
                      </a>
                    </li>
                    <li>
                      <a href="#pickupsRequest" data-toggle="tab">
                        Pickup Request
                      </a>
                    </li>
                    <li>
                      <a href="#challenges" data-toggle="tab">
                      Challenge List
                      </a>
                    </li>
              
                  </ul>
                </div> */}
                 <div className="tab-container">
                 <div className="tabs">
                  {visibleTabs.map(tab => (
                        <div
                          key={tab}
                          className={`tab ${activeQ === tab ? "active" : ""}`}
                          onClick={() => handleTabClick(tab)}
                        >
                          {tab}
                        </div>
                      ))}
                </div>
              </div> 
              </Col>
              <Col md="1"></Col>       
              <Col md="10">
                <div className="tab-content clearfix">
                <div className={`tab-pane ${activeQ === "Member List" ? "active" : ""} padded-tab`} id="memberList">
                    {teamData?.length !== 0 && (
                      <MemberListTable
                        teamData={teamData?.members}
                        memberUserId={teamData?._id}
                      />
                    )}
                  </div>

                  <div className={`tab-pane ${activeQ === "Pickup List" ? "active" : ""} padded-tab`} id="pickups">
                    {teamData?.length !== 0 && (
                      <PickupListTable
                        teamData={teamData?.pickups}
                        pickupUserId={teamData?._id}
                      />
                    )}
                  </div>
                  <div className={`tab-pane ${activeQ === "Pickup Request" ? "active" : ""} padded-tab`} id="pickupsRequest">
                    {teamData?.length !== 0 && (
                      <PickupRequestListTable
                        teamData={teamData?.pickups}
                        pickupRequestUserId={teamData?._id}
                      />
                    )}
                  </div>
                  <div className={`tab-pane ${activeQ === "Challenge List" ? "active" : ""} padded-tab`} id="challenges">
                    {teamData?.length !== 0 && (
                      <ChallengeListTable
                        teamData={teamData?.challenges}
                        challengeUserId={teamData?._id}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
      </div>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      
    </>
  );
}
