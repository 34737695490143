import React, { useState, useEffect, useRef } from "react";
import countries from './countries.json';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import Lightbox from "react-image-lightbox";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";


import FileUpload from "../../components/Common/FileUpload"; 

export default function CreateUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state?.row;
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [userData, setUserData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [rectangleImage, setRectangleImage] = useState(null);
  const [rectangleSelected, setrectangleSelected] = useState(0);
  const [rectangleImagePreview, setRectangleImagePreview] = useState(null);
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [completedCrop2, setCompletedCrop2] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [model, setModel] = useState(false);
 

  let history = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    return () => {
      if (rectangleImagePreview) URL.revokeObjectURL(rectangleImagePreview);
    };
  }, [rectangleImagePreview,]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const showLightBox2 = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox2(true);
  };


  const [cropRectangle, setCropRectangle] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 540,
    height: 300,
    aspect: 1.8,
    maxWidth: 540,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 540,
  });

  const updateCropRectangle = (crop) => {
    const aspectRatio = 1.8; // Desired aspect ratio (1.8:1)
    
    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }
  
    setCropRectangle(crop);
  };

  const handleCropComplete2 = (crop) => {
    setCompletedCrop2(crop);
  };

  const handleRectangleImageChange = (e) => {
    const file = e.target.files[0];
    setRectangleImage(file);
    setRectangleImagePreview(URL.createObjectURL(file));
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop2?.width &&
        completedCrop2?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop2,
          scale,
          rotate
        );

        setrectangleSelected(rectangleSelected + 1);
      }
    },
    100,
    [completedCrop2]
  );

  const Createuser = async (e) => {
    e.preventDefault();

    if (!userData?.firstName) {
      toast.error("Please enter First Name");
      return;
    }

    if (!userData?.phone) {
      toast.error("Please enter Phone");
      return;
    }
    
    if (userData?.phone.length < 7 || userData?.phone.length > 14) {
      toast.error("Please enter valid Phone");
      return;
    }

    if (!userData?.country_code) {
      toast.error("Please select Country Code");
      return;
    }   

    

    try {
      // Prepare image uploads concurrently


      const uploadPromises = [
        selectedImage ? postRequestForm("comman/img/upload", selectedImage) : Promise.resolve(null),
        rectangleSelected ? postRequestForm("comman/img/upload",  rectangleSelected) : Promise.resolve(null),
      ];

      let logoResponse = null;
      let rectangleResponse = null;

      // try {       
      //   [logoResponse, rectangleResponse] = await Promise.all(uploadPromises);
      // } catch (error) {       
      //   console.error("Failed to upload images:", error);
      //   toast.error("Failed to upload images.");
      // }
      
      const payload = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        platform: userData.platform,
        country_code: userData.country_code,
        gender: userData.gender,
        bio: userData.bio,
        nationality: userData.nationality,
        image: logoResponse?.data?.url || "",
        cover: rectangleResponse?.data?.url || "",
      };

      setLoading(true);
      const response = await postRequest("user/create", payload);
      setLoading(false);

      if (response.status == 1) {
        toast.success("User created successfully");
        setTimeout(() => {
          navigate("/users");
        }, 3000);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      setStartLoader(false);
      setLoading(false);
      console.error("Failed to create user:", error);
      toast.error("Failed to process the request.");
    } 
};



  const updateValue = (index, value) => {
    const list = { ...userData };
    list[index] = value;
    setUserData(list);
  };

  const updateValuePhone = (value) => {

    // check if value is number  
    if(isNaN(value)){     
      return;
    }
    if(value.length > 10){
      return;
    }
    const list = { ...userData };
    list['phone'] = value;
    setUserData(list);
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
      <Container >
      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>User Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">User Management</li>:{" "}
              <li className="breadcrumb-item">Create User</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history(-1)}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create User</h4>
                <Form onSubmit={Createuser}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            First Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("firstName", e.target.value)
                              }
                              value={userData?.firstName}
                              name="firstName"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Last Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("lastName", e.target.value)
                              }
                              value={userData?.lastName}
                              name="lastName"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Bio
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              onChange={(e) =>
                                updateValue("bio", e.target.value)
                              }
                              value={userData?.bio}
                              name="bio"
                              className="form-control"
                            />
                          </div>
                        </div>




                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              value={userData?.email}
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="tel"
                              onChange={(e) =>
                                updateValuePhone( e.target.value)
                              }
                              value={userData?.phone ?? ""}
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>
                    

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                              Country Code
                          </label>
                          <div className="col-sm-6">
                          <select
                          onChange={(e) =>
                            updateValue("country_code", e.target.value)
                          }
                          value={userData?.country_code}
                          name="country"
                          className="form-select"
                        >
                          <option value="">Select country code</option>
                          {countries.map((country) => (
                            <option key={country.phoneCode} value={country.phoneCode}>
                              {country.name} ({country.phoneCode})
                            </option>
                          ))}
                        </select>
                          </div>
                        </div> 

                        
                        
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Platform
                          </label>
                          <div className="col-sm-6">
                          <select
                            onChange={(e) => updateValue("platform", e.target.value)}
                            value={userData?.platform}
                            name="platform"
                            className="form-select"
                          >
                            <option value="">Select an option</option>
                            <option value="android">Android</option>
                            <option value="ios">iOS</option>
                            <option value="web">Web</option>
                          </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Gender
                          </label>
                          <div className="col-sm-6">
                          <select
                              onChange={(e) => updateValue("gender", e.target.value)}
                              value={userData?.gender}
                              name="gender"
                              className="form-select"
                            >
                              <option value="">Select an option</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="prefer_not_to_say">Prefer not to say</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Nationality
                          </label>
                          <div className="col-sm-6">
                          <select
                          onChange={(e) => updateValue("nationality", e.target.value)}
                          value={userData?.nationality}
                          name="country"
                          className="form-select"
                        >
                          <option value="">Select a country</option>
                          {countries.map((country) => (
                            <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                          </div>
                        </div> 

                         <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            DOB
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("dob", e.target.value)
                              }
                              value={userData?.dob}
                              name="dob"
                              className="form-control"
                            />
                          </div>
                        </div> 



                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Cover</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleRectangleImageChange} name="cover" className="form-control" />
                          <br />                         

                          {rectangleImagePreview ? (
                             <ReactCrop
                             crop={cropRectangle}
                             src={rectangleImagePreview}
                             onChange={(newCrop) => updateCropRectangle(newCrop)}
                             onComplete={handleCropComplete2}
                             style={{
                               maxWidth: "720px",
                               maxHeight: "600px",
                             }}
                             >
                             
                             <img ref={imgRef} src={rectangleImagePreview} />
                            </ReactCrop>
                         
                          ) : rectangleImagePreview ? (
                            <img
                              style={{ width: "250px", height: "100px" }}
                              src={rectangleImagePreview}
                              onClick={() => showLightBox2(rectangleImagePreview)}
                            />
                          ) : null}

                          {completedCrop2 && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop2.width,
                                  height: completedCrop2.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      </Container>
        
  

      </div>



      {openLightbox && (
          <Lightbox
            mainSrc={previewImage}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}

{openLightbox2 && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}
        <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
    
  );
}
