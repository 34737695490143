import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import Lightbox from "react-image-lightbox";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
  putRequestForm,
} from "../../components/Common/Utils";

import countries from './countries.json';

import FileUpload from "../../components/Common/FileUpload";

export default function Updateuserdata() {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [selectedFile, setSelectedFile] = useState("");
  const [image, setImage] = useState("");
  const [userdata, setData] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [rectangleImage, setRectangleImage] = useState(null);
  const [rectangleSelected, setrectangleSelected] = useState(0);
  const [rectangleImagePreview, setRectangleImagePreview] = useState(null);
  const [previewLogo, setPreviewLogo] = useState("");
  const [startLoader, setStartLoader] = useState(false);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [completedCrop2, setCompletedCrop2] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const location = useLocation();

  const { id } = useParams();
  let history = useNavigate();

  useEffect(() => {

    console.log(location);
    


    setPreviewImage(location.state?.row.image ? location.state?.row.image : "");
  }, [location.state]);

  useEffect(() => {
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  }, [selectedFile]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };
  const showLightBox2 = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox2(true);
  };


  const [cropRectangle, setCropRectangle] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 540,
    height: 300,
    aspect: 1.8,
    maxWidth: 540,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 540,
  });

  const updateCropRectangle = (crop) => {
    const aspectRatio = 1.8; // Desired aspect ratio (1.8:1)
    
    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }
  
    setCropRectangle(crop);
  };

  const handleCropComplete2 = (crop) => {
    setCompletedCrop2(crop);
  };

  const handleRectangleImageChange = (e) => {
    const file = e.target.files[0];
    setRectangleImage(file);
    setRectangleImagePreview(URL.createObjectURL(file));
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop2?.width &&
        completedCrop2?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop2,
          scale,
          rotate
        );

        setrectangleSelected(rectangleSelected + 1);
      }
    },
    100,
    [completedCrop2]
  );

  const updateuserdata = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    

    let Formvlaues = Object.fromEntries(data.entries());
    let formData = Formvlaues;
    let userData = formData;

    if (!userData?.first_name) {
      toast.error("Please enter First Name");
      return;
    }

    // if (!userData?.email) {
    //   toast.error("Please enter Email");
    //   return;
    // }

    if (!userData?.phone) {
      toast.error("Please enter Phone");
      return;
    }
    if (userData?.phone.length < 7 || userData?.phone.length > 14) {
      toast.error("Please enter valid Phone");
      return;
    }


    let dataToSend = {}
    const selectedImageForm = new FormData();
    let selectedImageRes = null;
    if (selectedFile) {
      selectedImageForm.append("image", selectedFile);
      setStartLoader(true);
      selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);
      setStartLoader(false);
    }


    if (selectedImageRes?.data?.url) {
      dataToSend.image = selectedImageRes.data.url;
    }

    dataToSend.userId = formData.userId;
    dataToSend.first_name = formData.first_name;
    dataToSend.last_name = formData.last_name;
    dataToSend.email = formData.email;
    dataToSend.phone = formData.phone;
    dataToSend.platform = formData.platform;   
    dataToSend.bio = formData.bio;
    dataToSend.gender = formData.gender;
    dataToSend.dateOfBirth  = formData.dob;

    postRequest("user/update/admin", dataToSend).then((data) => {
      let status = data;
      if (status.status == 1) {
        toast.success(status.message);
        setTimeout(() => {
          history.push("/users");
        }, 3000);
      } else {
        toast.error(status.message);
      }
    });
    //  }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
      <Container >
      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>User Management</h4>
            <div></div>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">User Management</li>:{" "}
              <li className="breadcrumb-item">
                <Link to="#">User update</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history(-1)}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>


      </div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Update User </h4>
              <form onSubmit={updateuserdata}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          First name
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            name="first_name"
                            defaultValue={location.state?.row.first_name}
                            className="form-control"
                            id="horizontal-firstname-input"
                          />
                          <input
                            type="hidden"
                            name="userId"
                            defaultValue={location.state?.row._id}
                            className="form-control"
                            id="horizontal-firstname-input"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Last Name
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            name="last_name"
                            defaultValue={location.state?.row.last_name}
                            className="form-control"
                            id="horizontal-email-input"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Bio
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            name="bio"
                            defaultValue={location.state?.row.bio}
                            className="form-control"
                            id="horizontal-email-input"
                          />                          
                        </div>
                      </div>


                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Email
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="email"
                            name="email"
                            defaultValue={location.state?.row.email}
                            className="form-control"
                            id="horizontal-password-input"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Phone
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="tel"
                            name="phone"
                            defaultValue={location.state?.row.phone}
                            className="form-control"
                            id="horizontal-password-input"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Platform
                        </label>

                        <div className="col-sm-6">
                          <select
                            defaultValue={location.state?.row.platform}
                            name="platform"
                            className="form-select"
                          >
                            <option value="select">Select an option</option>
                            <option value="android">Android</option>
                            <option value="ios">iOS</option>
                            <option value="web">Web</option>
                          </select>
                        </div>
                      </div>                     

                      <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Gender
                          </label>
                          <div className="col-sm-6">
                          <select                              
                              defaultValue={location.state?.row.gender || false}
                              name="gender"
                              className="form-select"
                            >
                              <option value="">Select an option</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Prefer not to say">Prefer not to say</option>
                            </select>
                          </div>
                        </div>                       

                         <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            DOB
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"                             
                              defaultValue={location.state?.row.dateOfBirth || false}
                              name="dob"
                              className="form-control"
                            />
                          </div>
                        </div> 


                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          image
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            className="form-control"
                            id="horizontal-password-input"
                          />
                          <input type="hidden" name="image" value={image} />
                          <br />
                          {previewImage && (
                            <img
                              src={previewImage}
                              onClick={() => showLightBox(previewImage)}
                              style={{ width: "100px", height: "100px" }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Cover</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleRectangleImageChange} name="cover" className="form-control" />
                          <br />                         

                          {rectangleImagePreview ? (
                             <ReactCrop
                             crop={cropRectangle}
                             src={rectangleImagePreview}
                             onChange={(newCrop) => updateCropRectangle(newCrop)}
                             onComplete={handleCropComplete2}
                             style={{
                               maxWidth: "720px",
                               maxHeight: "600px",
                             }}
                             >
                             
                             <img ref={imgRef} src={rectangleImagePreview} />
                            </ReactCrop>
                         
                          ) : rectangleImagePreview ? (
                            <img
                              style={{ width: "250px", height: "100px" }}
                              src={rectangleImagePreview}
                              onClick={() => showLightBox2(rectangleImagePreview)}
                            />
                          ) : null}

                          {completedCrop2 && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop2.width,
                                  height: completedCrop2.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row justify-content-end">
                        <div className="col-sm-9">
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </Container>
        
  

      </div>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
