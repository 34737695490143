import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner,Container } from "reactstrap";
import CustomAvatar from "../../components/Common/Avatar.js";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
} from "../../components/Common/Utils";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

 import "./CustomCard.css";

import { DetailsOutlined, Visibility } from "@material-ui/icons";
import TeamListTable from "./TeamlistTable";
import PickupListTable from "./PickuplistTable";
import ChallengeListTable from "./ChallengelistTable";
import PickupRequestListTable from "./PickupRequestlistTable";





const allTabs = [
  "Team List", "Pickup List", "Pickup Request", "Challenge List"];

  const getActiveQ = () => {
    const search = location.search;
    return new URLSearchParams(search).get("active");
  };

export default function Userdetails() {  
  const [activeQ, setActiveQ] = useState(getActiveQ() || "Team List");
  const [userData, setData] = useState([]);  
  const [loader, setLoader] = useState(true);
  const [openLightbox, setOpenLightBox] = React.useState(false);  
  const [visibleTabs, setVisibleTabs] = useState(allTabs.slice(0, 9));
  const [dropdownTabs, setDropdownTabs] = useState(allTabs.slice(9));

  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

    const handleTabClick = (tab) => {
    setActiveQ(tab);

    if (dropdownTabs.includes(tab)) {
      const newVisibleTabs = [...visibleTabs];
      const newDropdownTabs = [...dropdownTabs];
      const removedTab = newVisibleTabs.pop();
      newVisibleTabs.push(tab);

      const tabIndex = newDropdownTabs.indexOf(tab);
      newDropdownTabs.splice(tabIndex, 1);
      newDropdownTabs.push(removedTab);

      setVisibleTabs(newVisibleTabs);
      setDropdownTabs(newDropdownTabs);
    }

    setShowDropdown(false);
  };


  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#5e18c9", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    detailIcon: {
      color: "blue",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
  });

  const classes = useStyles();

  const { id } = useParams();


  const UserFetchDetail = async () => {
    setLoader(false);
    postRequest(`user/detail`, { userId: `${id}` }).then((data) => {
      let userdata = data.data;
      let status = data.status;
      setData(userdata);

      if (status == 1) {
        setLoader(false);
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    UserFetchDetail();
  }, []);

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

 
  const {
    first_name,
    last_name,
    dateOfBirth,
    gender,
    country_code,
    platform,
    about,
    social_platform,
    address,
    image,
    cover,
    phone,
    email,   
    createdAt,
  
  } = userData || {};

  const initials =
    userData?.first_name?.charAt(0).toUpperCase() +
    userData?.last_name?.charAt(0).toUpperCase();
  return (
    <>
      <React.Fragment>
      <div className="page-content">

      <Container fluid> 
        <Row>
          <Col className="10">
            <div className="page-title-box">
              <h4>User Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">User Management</li>:
                <li className="breadcrumb-item">User details</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.back()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>

          {loader ? (
            <CardBody style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "4rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </CardBody>
          ) : (
            <>
              <Col md="12">
                <Row>
                  <Card className="custom-card">
                    <div
                      className="cover-image"
                      style={{ backgroundColor: "#1d9bf0" }}
                    ></div>
                    <div className="profile-image">
                      {userData?.image ? (
                        <img
                          src={image}
                          onClick={() => showLightBox(image)}
                          alt="User Avatar"
                        />
                      ) : (
                        
                        <CustomAvatar
                          iconSize="74"
                          name={
                            first_name + last_name == ""
                              ? "*"
                              : `${first_name} ${last_name} `
                          }
                        />
                      )}
                    </div>

                    <div className="row my-2 py-2" style={{padding: "16px"}}>
                      <div
                        className="userinfo col-md-12 "
                        style={{ paddingLeft: "20px" }}
                      >
                        <br></br>

                        <div className="fst">
                          <h3>
                            {first_name} {last_name}
                            {name}
                          </h3>
                        </div>

                        <div className="row">
                          <div className="col-md-4 fst py-1">
                            <p>
                              DOB: <span> {dateOfBirth}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Gender: <span> {gender}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              phone:
                              <span>
                                {country_code} {phone}
                              </span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Email: <span> {email}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Platform: <span> {platform}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Social Platform: <span> {social_platform}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Created At: <span> {formatDate(createdAt)}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Row>
              </Col>
              <Col md="12" style={{marginLeft:"inherit"}}>
                {/* <div
                  id="exTab2"
                  className="twitter-like-tabs2"
                  style={{ background: "#ffff", marginLeft: "25px" }}
                >
                  <ul
                    className="nav nav-pills"
                    style={{ width: "480px", display: "contents" }}
                  >
                    <li className="active">
                      <a href="#teamList" data-toggle="tab">
                        Team List
                      </a>
                    </li>

                    <li>
                      <a href="#pickups" data-toggle="tab">
                        Pickup List
                      </a>
                    </li>
                    <li>
                      <a href="#pickupsRequest" data-toggle="tab">
                        Pickup Request
                      </a>
                    </li>
                    <li>
                      <a href="#challenges" data-toggle="tab">
                      Challenge List
                      </a>
                    </li>
              
                  </ul>
                </div> */}

              <div className="tab-container">
              <div className="tabs">
                  {visibleTabs.map(tab => (
                        <div
                          key={tab}
                          className={`tab ${activeQ === tab ? "active" : ""}`}
                          onClick={() => handleTabClick(tab)}
                        >
                          {tab}
                        </div>
                      ))}
                </div>
              </div>  


              </Col>
              <Col md="1"></Col>        
              <Col md="10">
                <div className="tab-content clearfix">
                  <div className={`tab-pane ${activeQ === "Team List" ? "active" : ""} padded-tab`} id="teamList">
                    {userData?.length !== 0 && (
                      <TeamListTable
                        userData={userData?.teams}
                        teamUserId={userData?._id}
                      />
                    )}
                  </div>

                  <div className={`tab-pane ${activeQ === "Pickup List" ? "active" : ""} padded-tab`} id="pickups">
                    {userData?.length !== 0 && (
                      <PickupListTable
                        userData={userData?.pickups}
                        pickupUserId={userData?._id}
                      />
                    )}
                  </div>
                  <div className={`tab-pane ${activeQ === "Pickup Request" ? "active" : ""} padded-tab`} id="pickupsRequest">
                    {userData?.length !== 0 && (
                      <PickupRequestListTable
                        userData={userData?.pickups}
                        pickupRequestUserId={userData?._id}
                      />
                    )}
                  </div>
                  <div className={`tab-pane ${activeQ === "Challenge List" ? "active" : ""} padded-tab`} id="challenges">
                    {userData?.length !== 0 && (
                      <ChallengeListTable
                        userData={userData?.challenges}
                        challengeUserId={userData?._id}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
      </div>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      
    </>
  );
}
