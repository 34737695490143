import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Form,
  Table,
  Button,
  Modal,
  Spinner,
  Container
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import {
  postRequest,  
  postCustomRequestForm  
 
} from "../../components/Common/Utils.js";
import icon1 from "../../assets/images/icon1.png";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import Select from "react-select";
import ActivityModal from "../../components/Modal/ActivityModal.js";
import BannerModal from "../../components/Modal/BannerModal.js";
import TeamModal from "../../components/Modal/TeamModal.js";
import VideoModal from "../../components/Modal/VideoModal.js";
import noImage from "../../assets/images/No_Image_Available.jpg";
import Countdown from "./CountDown.js";
import { set } from "lodash";
import FileUpload from "../../components/Common/FileUpload.js";
import DragDropModal from "./DragDropModal.js";

export default function CreateHomeBlock() {
  const location = useLocation();
  const state = location.state?.row;

  const [homeData, setHomeBlockData] = useState({isAuto: "0"});

  // new changes
  const [previewImage, setPreviewImage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [customcomp, setCustomComp] = useState([]);
  // new chanes

  const [categoryList, setCategoryList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [venueList, setVenueList] = useState([]);
  const [coverLogo, setCoverLogo] = useState([]);
  const [offerListDetail, setOfferListDetail] = useState([]);
  const [venueListDetail, setVenueListDetail] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [dealList, setDealList] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState([]);
  const [videoMdl, setVideoMdl] = useState(false);

  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);

  const [componentList, setComponentList] = useState([]);

  const [vouchars, setVouchars] = useState([]);

  const [venuegetId, setVenueId] = useState([]);
  const [offergetId, setOfferId] = useState([]);
  const [venuPreview, setVenuPreview] = useState([]);
  const [offerPreview, setOfferPreview] = useState([]);

  const [venueText, setVenueText] = useState("");
  const [idName, setIdName] = useState("venueId");
  const [typecustom, setTypeCustom] = useState(false);
  const [model, setModel] = useState(false);
  const [modelcustom, setModelCustom] = useState(false);
  const [dragDropData, setDragDropData] = useState([]);

  const [bannerModal, setBannerModal] = useState(false);
  const [bannerId, setBannerId] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [bannerImage, setBannerImage] = useState("");

  // new changes
  const [customModalComponent, setModelCustomComponent] = useState(false);
  const [modelDeal, setModelDeal] = useState(false);
  const [modelActivity, setModelActivity] = useState(false);
  const [modelTeam, setModelTeam] = useState(false);
  const [modelBanner, setModelBanner] = useState(false);
  const [modelVoucher, setModelVoucher] = useState(false);
  const [modelEvent, setModelEvent] = useState(false);
  const [model2, setModel2] = useState(false);

  const [venueoptions, setvenueOptions] = useState([]);
  const [categoryoptions, setcategoryOptions] = useState([]);
  const [offeroptions, setofferOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [selectedVideo, setSelectedVideo] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [selectedSlider, setSelectedSlider] = useState([]);
  const [urlCover, setURL] = useState([]);

  const [open, setOpen] = useState(false);
  const [packages, setPackages] = useState([]);

  const [lowestDiscount, setLowestDiscount] = useState("");
  const [editIndex, setEditIndex] = useState(null);

  const [activityPreview, setActivityPreview] = useState([]);
  const [teamPreview, setTeamPreview] = useState([]);
  const [bannerPreview, setBannerPreview] = useState([]);
  const [eventPreview, setEventPreview] = useState([]);
  const [activityId, setActivityId] = useState([]);
  const [teamId, setTeamId] = useState([]);
  const [eventId, setEventId] = useState([]);
  const [selectedDeals, setSelectedDeals] = useState([]);

  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [startLoader, setStartLoader] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  // dragdrop state
  const [showOrder, setShowOrder] = useState(false);

  useEffect(() => {
    // fetchList();
    // fetchTheme();
    // fetchMusic();
    // fetchFeature();
    // fetchCuisine();
  }, []);

  const AddUploadedVideo = (video) => {
    setUploadedVideo([...uploadedVideo, video]);
  }; 
 

  

 
  

  const closeVideoModal = () => {
    setVideoMdl(false);
  };

  useEffect(() => {
    // need to calculate lowest discount discount is string so we need to convert it to number
    var lowest = 100000;
    if (vouchars.length > 0) {
      lowest = vouchars.reduce((prev, curr) => {
        return parseFloat(prev.discountValue) < parseFloat(curr.discountValue)
          ? prev
          : curr;
      });
    }
    setLowestDiscount(lowest ? lowest.discountValue : "");
  }, [vouchars]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const saveVoucher = (index) => {
    setVouchars(
      vouchars.map((voucher, i) =>
        i === index ? { ...voucher, saved: true } : voucher
      )
    );
    setEditIndex(null);
  };

  const deleteVoucher = (index) => {
    setVouchars(vouchars.filter((_, i) => i !== index));
  };

  const pushOferPackage = (data) => {
    const tempPack = [...packages];
    tempPack.push(data);
    setPackages(tempPack);
  };

  const deletePackage = async (index, id) => {
    if (id) {
      const tempPack = [...packages];
      tempPack.splice(index, 1);
      setPackages(tempPack);
      const response = await deleteRequest("offer/package/delete", {
        packageId: id,
        offerId: state?._id,
      });
      return;
    }

    const tempPack = [...packages];
    tempPack.splice(index, 1);
    setPackages(tempPack);
  };

  const msUpdateDeal = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDeal(ids);
  };

  const toggleModal = () => {
    setOpen(!open);
  };
  const closeModalSlider = () => {
    setOpen(false);
  };

  // const msUpdateVideo = (selectedList, selectedItem) => {
  //   const ids = selectedList.map((item) => item.id);
  //   setSelectedVideo(ids);
  // };

  const msUpdateComponent = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedComponent(ids);
  };

  const msUpdateVenue = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedVenue(ids);
  };

  const msUpdateOffer = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedOffer(ids);
  };

  let history = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    // fetchList();
    // fetchVideoList();
    // fetchOfferList();
     fetchSizeList();
    // fetchComponentList();
    // fetchDealList();
    // fetchCategoryList();
    // fetchCategoryList2();
  }, []);

  // const fetchCategoryList2 = async () => {
  //   const param = { page: 1, limit: 100000 };
  //   const category = await postRequest("venue/category/list/admin", param);
  //   if (category.status == 1) {
  //     const options = category.data.list.map((item) => {
  //       return { id: item._id, name: item.title };
  //     });

  //     setCategoryList(options);
  //     if (selectedCategory.length > 0) {
  //       const defaultItems = options.filter((item) => {
  //         return selectedCategory.includes(item.id);
  //       });
  //       setDefaultCategory(defaultItems);
  //     }
  //   }
  // };

  // const fetchDealList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("homeblock/deal/list", {
  //     limit: 10000000,
  //   });
  //   if (response.status == 1) {
  //     const list = response.data.list.map((item) => {
  //       return {
  //         id: item._id,
  //         name: item.title,
  //       };
  //     });
  //     setDealList(list);
  //   }
  //   setLoading(false);
  // };

  // const fetchComponentList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("homeblock/custom/list", {
  //     limit: 10000000,
  //   });
  //   if (response.status == 1) {
  //     const list = response.data.list.map((item) => {
  //       return {
  //         id: item._id,
  //         name: item.title,
  //       };
  //     });
  //     setComponentList(list);
  //   }
  //   setLoading(false);
  // };

  const fetchSizeList = async () => {
    setLoading(true);
    const response = await postRequest("home-block/size/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          type: item.type,
          ratio: item.ratio,
        };
      });
      setSizeList(list);
    }
    setLoading(false);
  };

  // const fetchOfferList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("venue/offer/list/admin", {
  //     limit: 10000000,
  //   });

  //   if (response.status == 1) {
  //     const list = response.data.list.map((item) => {
  //       return {
  //         id: item._id,
  //         name: item.title,
  //       };
  //     });
  //     setOfferList(list);
  //     const mappedOptions = response.data.list.map((item) => ({
  //       value: item._id,
  //       label: item.title,
  //     }));
  //     setofferOptions(mappedOptions);
  //     setOfferListDetail(response.data.list);
  //   }
  //   setLoading(false);
  // };

  // const fetchList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("venue/list", { limit: 10000000, sortField: "createdAt", sortOrder: "desc"});
  //   if (response.status == 1) {
  //     setVenueListDetail(response.data.list);
  //     const list = response.data.list.map((item) => {
  //       return {
  //         id: item._id,
  //         name: item.name,
  //       };
  //     });
  //     setVenueList(list);
  //     const mappedOptions = response.data.list;
  //     setvenueOptions(mappedOptions);
  //   }
  //   setLoading(false);
  // };



  // const fetchCategoryList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("venue/category/list/admin", {
  //     limit: 10000000,
  //   });
  //   if (response.status == 1) {
  //     setcategoryOptions(response.data.list);
  //   }
  //   setCategoryList(options);

  //   setLoading(false);
  // };

  // const fetchVideoList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("homeblock/video/list", {
  //     limit: 10000000,
  //   });
  //   if (response.status == 1) {
  //     const list = response.data.list.map((item) => {
  //       return {
  //         id: item._id,
  //         name: item.title,
  //       };
  //     });
  //     setVideoList(list);
  //   }
  //   setLoading(false);
  // };

  const toggleModel = () => {
    setModel(!model);
  };
  const toggleModel2 = () => {
    setModel2(!model2);
  };
  const toggleModelCustom = () => {
    setModelCustom(!modelcustom);
  };

  const closeBannerModal = () => {
    setBannerModal(false);
  }

  const toggleBannerModal = () => {
    setBannerModal(!bannerModal);
  }

  const selectBannerRow = (selectRow) => {
    console.log(selectRow);

    setBannerId(selectRow?._id);
    setBannerText(selectRow?.title);
    setBannerImage(selectRow?.items[0]?.image );
    closeBannerModal();

  }

  // new changes
  const CustomMOdalComponenet = () => {
    setModelCustomComponent(!customModalComponent);
  };
  const toggleModelDeal = () => {
    setModelDeal(!modelDeal);
  };

  const toggleModelActivity = () => {
    setModelActivity(!modelActivity);
  };

  const toggleModelTeam = () => {
    setModelTeam(!modelTeam);
  };

  const toggleModelBanner = () => {
    setModelBanner(!modelBanner);
  };

  const toggleModelVoucher = () => {
    setModelVoucher(!modelVoucher);
  };

  const toggleModelEvent = () => {
    setModelEvent(!modelEvent);
  };

  const closeModal2 = () => {
    setModel2(false);
  };

  const closeModal3 = () => {
    setModelActivity(false);
  };
  const closeModalT = () => {
    setModelTeam(false);
  };
  const closeModalB = () => {
    setModelBanner(false);
  };
  const closeModalVoucher = () => {
    setModelVoucher(false);
  };
  const closeModal4 = () => {
    setModelEvent(false);
  };
  const closeModal = () => {
    setModel(false);
    setModelDeal(false);
    setModelCustom(false);
    // new changes
    setPreviewImage("");
    setSelectedImage("");
    setModelCustomComponent(false);
  };

  const selectRow = (selectRow, id, name) => {
    let arr = [],
      cover,
      logo,
      address,
      label;
    cover = selectRow.cover;
    logo = selectRow.logo;
    address = selectRow.address;
    label = selectRow.name;
    arr = { cover, logo, address, label };
    setVenuPreview([...venuPreview, arr]);
    setVenueId([...venuegetId, id]);
    setVenueText(name);
    closeModal();
  };

  const selectRow2 = (selectRow2, id) => {
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      description;
    title = selectRow2.title;
    description = selectRow2.description;
    cover = selectRow2.image;
    logo = selectRow2.venue.logo;
    address = selectRow2.venue.address;
    label = selectRow2.venue.name;
    arr = { cover, logo, address, label, title, description };
    setOfferPreview([...offerPreview, arr]);
    setOfferId([...offergetId, id]);
    closeModal2();
  };

  const selectActivityRow = (id, selectRow) => {
    // check if activity is already added

    console.log({ selectRow });

    const isActivityAlreadyAdded = activityId.includes(id);



    if (isActivityAlreadyAdded) {
      toast.error("Activity already added");
      return;
    }
    closeModal3();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      icon,
      description;
    title = selectRow.title;
    icon = selectRow.icon;
    description = selectRow?.description?.description;
    cover = icon;
    logo = icon;
    address = title;
    label = selectRow.level;
    arr = { cover, logo, address, label, title, icon, description };
    setActivityPreview([...activityPreview, arr]);
    setActivityId([...activityId, id]);
  };

  const selectTeamRow = (id, selectRow) => {
    // check if activity is already added

    console.log({ selectRow });

    const isTeamAlreadyAdded = teamId.includes(id);

    if (isTeamAlreadyAdded) {
      toast.error("Team already added");
      return;
    }

    closeModalT();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      icon,
      userlogo,
      description;
    title = selectRow.name;
    icon = selectRow.image;   
    description = selectRow?.bio;
    cover = selectRow?.image;
    userlogo = selectRow?.ownerData?.image;
    logo = selectRow.image;


    address = `${selectRow?.ownerData?.first_name} ${selectRow?.ownerData?.last_name}`;
    label = selectRow?.level;

    arr = { cover, logo, address, label, title, icon, userlogo, description };

    setTeamPreview([...teamPreview, arr]);
    setTeamId([...teamId, id]);
  };

  const selectBannRow = (selectRow, id) => {
    // check if activity is already added
  
    if (isBannerAlreadyAdded) {
      toast.error("Banner already added");
      return;
    }

    closeModalB();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      icon,
      description;
    title = selectRow.type;
    icon = selectRow.image;
    description = "";
    cover = icon;
    logo = icon;
    address = selectRow.title;
    label = selectRow.provider?.name;
    arr = { cover, logo, address, label, title, icon, description };
    setBannerPreview([...bannerPreview, arr]);
    setBannerId([...bannerId, id]);
  };
  const selectVoucherRow = (selectRow, id) => {
    // check if activity is already added

    const isVoucherAlreadyAdded = voucherId.includes(id);

    if (isVoucherAlreadyAdded) {
      toast.error("Voucher already added");
      return;
    }

    closeModal3();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      description;
    title = selectRow.name;
    description = selectRow?.description?.description;
    cover = selectRow.provider?.logo;
    logo = selectRow.provider?.logo;
    address = selectRow.provider?.address;
    label = selectRow.provider?.name;
    arr = { cover, logo, address, label, title, description };
    setActivityPreview([...activityPreview, arr]);
    setActivityId([...activityId, id]);
  };
  const selectEventRow = (selectRow, id) => {
    // check if activity is already added

    const isEventAlreadyAdded = eventId.includes(id);

    if (isEventAlreadyAdded) {
      toast.error("Event already added");
      return;
    }

    closeModal4();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      discount,
      description;
    title = selectRow.title;
    description = selectRow?.description;
    cover = selectRow?.image;
    logo = selectRow?.orgLogo;
    address = selectRow?.orgAddress;
    discount = selectRow?.pack?.discountValue;

    label = selectRow?.orgName;

    arr = { cover, label, logo, address, title, description, discount };
    setEventPreview([...eventPreview, arr]);
    setEventId([...eventId, id]);
  };

  const [formValues, setFormValues] = useState([]);

  const [currentForm, setCurrentForm] = useState({
    title: "",
    subTitle: "",
    description: "",
    info: "",
    badge: "",
    typeselect: "",
    type: "",
  });

  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setCurrentForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const updateCategory = (e) => {
    setCurrentForm((prevForm) => ({
      ...prevForm,
      category: e.value,
    }));
  };

  ///new changes
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
    const formData = new FormData();
    formData.append("image", file);
    setLoading1(true);
    let response = await postCustomRequestForm("image/upload", formData);
    setLoading1(false);
    setCurrentForm((prevForm) => ({
      ...prevForm,
      ["image"]: response.data.url,
    }));
  };

  const CustomFormValue = (event) => {
    event.preventDefault();

    // Reset states
    setCoverLogo([]);
    setPreviewImage(null);
    setSelectedImage(null);
    setCustomComp([]);

    // Reset currentForm state
    setCurrentForm({
      title: "",
      description: "",
      info: "",
      badge: "",
      typeselect: "",
      type: "",
    });

    // Extract data from coverLogo state
    const newData = coverLogo[0];
    console.log({ newData });

    // Update formValues state with currentForm
    setFormValues((prevValues) => [...prevValues, currentForm]);

    // Merge newData and currentForm
    const mergeData = { ...newData, ...currentForm };
    console.log({ mergeData });

    // Update mergeformData state
    setMergeformData((prevValues) => [...prevValues, mergeData]);
  };

  const CustomFormDeal = (event) => {
    event.preventDefault();
    // setInputs('');

    // get current form data

    setCoverLogo([]);
    const newData = {
      title: currentForm.title,
      description: currentForm.description,
      venue: coverLogo[0]?.bid,
      category: currentForm.category,
      startDate: currentForm.startDate,
      endDate: currentForm.endDate,
      image: selectedImage,
      lowestDiscount: lowestDiscount,
      ...coverLogo[0],
    };
    newData.vouchars = vouchars;
    pushData(newData);
    setCurrentForm({
      title: "",
      category: "",
      description: "",
      venue: "",
      startDate: "",
      endDate: "",
    });
    setVouchars([]);
    setFormValues((prevValues) => [...prevValues, newData]);
    setCoverLogo([]);

    closeModal();
  };

  useEffect(() => {
    if (formValues.length !== 0) {
      setModelCustom(false);
      setModelCustomComponent(false);
    }
  }, [formValues, venuPreview, venuegetId, offerPreview, offergetId]);

  const handleRemovevenuPreview = (index) => {
    const newArray = [...venuPreview];
    newArray.splice(index, 1);
    setVenuPreview(newArray);
    const newvenue = [...venuegetId];
    newvenue.splice(index, 1);
    setVenueId(newvenue);
  };
  const handleRemoOfferPreview = (index) => {
    const newArray = [...offerPreview];
    newArray.splice(index, 1);
    setOfferPreview(newArray);
    const newvenue = [...offergetId];
    newvenue.splice(index, 1);
    setOfferId(newvenue);
  };

  const handleRemoveActivityPreview = (index) => {
    const newArray = [...activityPreview];
    newArray.splice(index, 1);
    setActivityPreview(newArray);
    const newvenue = [...activityId];
    newvenue.splice(index, 1);
    setActivityId(newvenue);
  };

  const handleRemoveTeamPreview = (index) => {
    const newArray = [...teamPreview];
    newArray.splice(index, 1);
    setTeamPreview(newArray);
    const newvenue = [...teamId];
    newvenue.splice(index, 1);
    setTeamId(newvenue);
  };
  const handleRemoveBannerPreview = (index) => {
    const newArray = [...bannerPreview];
    newArray.splice(index, 1);
    setBannerPreview(newArray);
    const newvenue = [...bannerId];
    newvenue.splice(index, 1);
    setBannerId(newvenue);
  };

  const handleRemoveEventPreview = (index) => {
    const newArray = [...eventPreview];
    newArray.splice(index, 1);
    setEventPreview(newArray);
    const newvenue = [...eventId];
    newvenue.splice(index, 1);
    setEventId(newvenue);
  };
  // const handleRemoveVideoPreview = (index) => {
  //   const newArray = [...uploadedVideo];
  //   newArray.splice(index, 1);
  //   setUploadedVideo(newArray);
  //   console.log(uploadedVideo);
  // };
  const handleRemoveVideo = (index) => {
    let newUploadedVideo = uploadedVideo.filter((_, idx) => idx !== index);
    setUploadedVideo(newUploadedVideo);
    // let tempUploaded = [...uploadedVideo]
    // tempUploaded = tempUploaded.filter((item) => item._id != id);
    // setUploadedVideo([...tempUploaded]);
  };

  const handleRemoveFormValue = (index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
    setFormValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });

    setMergeformData((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
  };

  const createHomeBlock = async (e) => {
    e.preventDefault();

    // if (!homeData.title) {
    //   toast.error("Title is required");
    //   return;
    // }

    // if (!homeData.size) {
    //    homeData.size = "{64743d1ba021f3bf326ab3ce}";
    //   toast.error("Size is required");
    //   return;
    // }

    if (!homeData.type) {
      toast.error("Type is required");
      return;
    }

    if (homeData.type != "video" && homeData.type != "my-outing") {
      if (!homeData.title) {
        toast.error("Tittle is required");
        return;
      }
    }

    if (homeData.type == "venue" && !homeData.size) {
      // homeData.size = "64743d1ba021f3bf326ab3ce";
      toast.error("Size is required");
      return;
    }

    // if(!homeData.description){
    //   toast.error("Description is required");
    //   return;
    // }

    if (!homeData.type) {
      toast.error("Type is required");
      return;
    }

    if (homeData.type == "video" && !uploadedVideo.length) {
      toast.error("Please select video");
      return;
    }

    if (homeData.type == "deal" && !selectedDeals.length) {
      toast.error("Please add deals");
      return;
    }

    if (
      homeData.type == "venue" &&
      homeData.isAuto == "0" &&
      !venuegetId.length
    ) {
      toast.error("Please select venue");
      return;
    }

    if (
      homeData.type == "venue" &&
      homeData.isAuto == "1" &&
      !selectedTheme.length &&
      !selectedCuisine.length &&
      !selectedMusic.length &&
      !selectedFeature.length
    ) {
      toast.error("Please select atleast one Category");
      return;
    }

    if (homeData.type == "activity" && !activityId.length) {
      toast.error("Please select activity");
      return;
    }

    if (
      homeData.type == "offer" &&
      homeData.isAuto == "0" &&
      !offergetId.length
    ) {
      toast.error("Please select offer");
      return;
    }

    if (homeData.type == "event" && homeData.isAuto == "0" && !eventId.length) {
      toast.error("Please select event");
      return;
    }

    if (homeData.type == "slider" && !selectedSlider.length) {
      toast.error("Please select slider");
      return;
    }

    if (homeData.type == "custom-venue" && !formValues.length) {
      toast.error("Please add custom venue");
      return;
    }

    if (homeData.type == "custom-offer" && !formValues.length) {
      toast.error("Please add custom offer");
      return;
    }

    if (homeData.type == "custom-components" && !formValues.length) {
      toast.error("Please add custom components");
      return;
    }

    if (
      homeData.type == "offer" &&
      homeData.isAuto == "1" &&
      homeData.promoteOffer == "yes" && !offergetId.length) {
      toast.error("Please select atleast one any promote offer");
      return;
    }
    if (
      homeData.type == "event" &&
      homeData.isAuto == "1" &&
      homeData.promoteEvent == "yes" && !eventId.length) {
      toast.error("Please select atleast one any promote event");
      return;
    }
    if (
      homeData.type == "venue" &&
      homeData.isAuto == "1" &&
      homeData.promoteVenue == "yes" && !venuegetId.length) {
      toast.error("Please select atleast one any promote venue");
      return;
    }

    // setLoading(true);

    let dealIds = selectedDeals.map((deal) => deal._id);

    let uVideos = uploadedVideo?.map((video) => video._id);

    const payload = {
      ...homeData,
      offers: homeData.isAuto === "1" && homeData.promoteOffer === "yes" || homeData.isAuto === "0" ? offergetId : [],
      // offers: homeData.isAuto === "0" ? offergetId : offergetId,
      sliders: selectedSlider,
      events: homeData.isAuto === "1" && homeData.promoteEvent === "yes" || homeData.isAuto === "0" ? eventId : [],
      videos: uVideos,
      customComponents: selectedComponent,
      // venues: homeData.isAuto === "0" ? venuegetId : venuegetId,
      venues: homeData.isAuto === "1" && homeData.promoteVenue === "yes" || homeData.isAuto === "0" ? venuegetId : [],
      deals: homeData.type == "deal" ? dealIds : [],


      // offers: selectedOffer,
      categoryForOffer: selectedCategory,
      customVenues: homeData.type == "custom-venue" ? MergeformData : [],
      // customVenues: homeData.type == "custom-venue" ? formValues : [],
      activities: homeData.type == "activity" ? activityId : [],
      banners: homeData.type == "banner" || 'create_challenge' ? bannerId : [], 
      teams: homeData.type == "team" ? teamId : [],
      // customOffers: homeData.type == "custom-offer" ? formValues : [],
      customOffers: homeData.type == "custom-offer" ? MergeformData : [],
      customComponents: homeData.type == "custom-components" ? formValues : [],
    };

    if (homeData.type == "venue" && homeData?.isAuto == "1") {
      let currentCategoryForAuto = {
        themes: selectedTheme,
        musics: selectedMusic,
        features: selectedFeature,
        cuisines: selectedCuisine,
      };
      payload.venueFilterForAuto = currentCategoryForAuto;
    }

 
    if (homeData.type == "venue") {
      payload.size = homeData.size;
    }

    const response = await postRequest("home-block/create", payload);
    setLoading(false);
    const newId = response?.data?._id;

    if (response.status === 1) {
      
      toast.success("Home Block created successfully");
      setTimeout(() => {
        history(-1);
      }, 3000);

      
    } else {
      toast.error(response.message);
    }
  };

  // new changes
  const customValue = (index, value, name) => {
    const list = { ...customcomp };
    list[index] = value;
    setCustomComp(list);
    setCurrentForm((prevForm) => ({
      ...prevForm,
      ["type"]: value,
    }));

    if (index == "type") {
      if (value == "offer") {
        setIdName("offerId");
      }
      if (value == "venue") {
        setIdName("venueId");
      }
    }
  };
  // end new changes
  const updateValue = (index, value) => {
    const list = { ...homeData };
    list[index] = value;
    if (index == "type") {
      if (value == "custom-offer") {
        setTypeCustom("custom");
        setIdName("offerId");
        setCoverLogo([]);
        setFormValues([]);
        setCurrentForm([]);
        setData([]);
        setMergeformData([]);
        // new changes
        setPreviewImage(null);
        setSelectedImage(null);
      }

      if (value == "custom-venue") {
        setIdName("venueId");
        setTypeCustom("custom");
        setCoverLogo([]);
        setFormValues([]);
        setCurrentForm([]);
        setMergeformData([]);
        setData([]);
        // new changes
        setPreviewImage(null);
        setSelectedImage(null);
      }
      // new changes
      if (value == "custom-components") {
        setIdName("venueId");
        setTypeCustom("custom");
        setCoverLogo([]);
        setFormValues([]);
        setCurrentForm([]);
        setData([]);
        setPreviewImage(null);
        setSelectedImage(null);
      }
      if (value == "event") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "recent";
      }
      if (value == "venue") {
        list["isAuto"] = "0";
        list["categoryForAuto"] = "all";
      }
      if (value == "offer") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "all";
      }
      if (value != "venue" && value != "event" && value != "offer") {
        list["isAuto"] = "0";
        list["categoryForAuto"] = "";
      }
    }
    setHomeBlockData(list);
  };

  // new changes
  const handleCoverChange = (e, index) => {
    let arr = [],
      cover,
      logo,
      address,
      bid,
      label,
      covercustom; // new changes
    arr = arr.concat(coverLogo);
    let value = e.value;
    let title = e.label;

    if (
      homeData?.type == "custom-venue" ||
      homeData?.type == "deal" ||
      customcomp?.type == "venue"
    ) {
      let result = venueListDetail.find((i) => {
        if (i._id == value) {
          return i;
        }
      });
      cover = result.cover;
      logo = result.logo;
      address = result.address;
      label = title;
      bid = value;
      covercustom = previewImage;
      // new changes
    } else if (
      homeData?.type == "custom-offer" ||
      customcomp?.type == "offer"
    ) {
      let result = offerListDetail.find((i) => {
        if (i._id == value) {
          return i;
        }
      });
      cover = result.venue.cover;
      logo = result.venue.logo;
      address = result.venue.address;
      label = title;
    }
    arr[0] = { cover, logo, address, label, bid, covercustom };
    setCoverLogo(arr);
    setCurrentForm((prevForm) => ({
      ...prevForm,
      [idName]: value,
    }));
  };

  const [customdata, setData] = useState([]);
  const [MergeformData, setMergeformData] = useState([]);

  const [VenuePromoteModal, setVenuePromote] = useState(false);
  const pushData = (newData) => {
    setData((prevData) => [...prevData, newData]);
  };

  const toggleVenuePromote = () => {
    setVenuePromote(!model2);
  };

  // DragDropTable

  const DragDropTable = () => {
    if (homeData.type === "venue") {
      setDragDropData(venuPreview);
    } 
     else if (homeData.type === "activity") {
      setDragDropData(activityPreview);
    } else if (homeData.type === "team") {
      setDragDropData(teamPreview);
    }
    else if (homeData.type === "banner") {
      setDragDropData(bannerPreview);
    }
    setShowOrder(true);
  };

  const updateDragDropData = async (data) => {
    setDragDropData(data);
    if (homeData.type == "venue") {
      setVenuPreview(data);
    } else if (homeData.type == "offer") {
      setOfferPreview(data);
    } else if (homeData.type == "video") {
      console.log({ data })
      setUploadedVideo(data);
    } else if (homeData.type == "custom-venue") {
      setMergeformData(data);
    } else if (homeData.type == "custom-offer") {
      setMergeformData(data);
    } else if (homeData.type === "event") {
      setEventPreview(data);
    } else if (homeData.type === "activity") {
      setActivityPreview(data);
      
    } else if (homeData.type == "team") {
      setTeamPreview(data);
    }
    else if (homeData.type == "banner") {
      setBannerPreview(data);
    }
    else if (homeData.type === "deal") {
      setSelectedDeals(data);
      setFormValues(data);
    }
    setShowOrder(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="page-content">

        <Container >
      
      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Home Block Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Home Block Management</li>:{" "}
              <li className="breadcrumb-item">Create Home Block</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history(-1)}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Home Block </h4>
                <Form onSubmit={createHomeBlock}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            // required="required"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Show Title in App
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              defaultChecked={true}
                              onChange={(e) =>
                                updateValue("showTitle", e.target.checked)
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            // required="required"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>

                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              name="mediaType"
                              class="form-select"
                            >
                              <option value="">Select</option>
                              <option value="banner">Banner</option>                  
                              <option value="team">Team</option>   
                              <option value="create_team">Create Team</option>
                              <option value="create_challenge">Create Challenge</option>
                              <option value="video">Video</option>
                              <option value="invite_friend">Invite Friend</option>
                              <option value="challenge">Challenge</option>
                              <option value="my_team">My Team</option>

                            </select>
                          </div>
                        </div>           
                      

                      {homeData?.type == "team" && (
                          <>
                            <div className="row align-items-center">
                              {teamPreview.map((form, index) => (
                                <div className="col-lg-4 mb-3">
                                  <div
                                    class="box-custom red-border "
                                    className={
                                      form[index]
                                        ? "box-custom bgcard"
                                        : "box-custom"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      backgroundImage: `url(${form?.icon})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    {/* <Countdown endDate={form.endDate} /> */}

                                    <div class="badge-deal">
                                      {/* <img src={icon1} alt="icon" /> */}
                                      {form?.label ? form?.label : "Friendly"}
                                    </div>

                                    <h5
                                      className="title"
                                      style={{
                                        fontSize: "32px",
                                        textAlign: "left",
                                        marginTop: "10px",
                                        color: "#fff",
                                      }}
                                    >
                                      {form.title ? form.title : "Title"}
                                    </h5>

                                    <h4
                                      style={{
                                        fontFamily: "Montserrat",
                                        textAlign: "left",
                                        fontSize: "20px",
                                        lineHeight: "36px",
                                        color: "#fff",
                                      }}
                                    // style={{ textAlign: "left" }}
                                    // className="subtitle"
                                    >
                                      {form.description
                                        ? form.description
                                        : "Description"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="d-flex align-items-center deal-footer">
                                        <div
                                          className="logo"
                                          style={{ background: "inherit" }}
                                        >
                                          <img
                                            src={form?.userlogo || noImage}
                                            alt="logo"
                                            className="rounded-circle"
                                          />
                                        </div>

                                        <div>
                                          {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                                          {/* <h3>{inputs[index]?.selectCustom ? inputs[index]?.selectCustom.label : 'Select Venue'}</h3> */}
                                          
                                          <p style={{ fontSize: "20px" }}>
                                            {form?.address
                                              ? form?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveTeamPreview(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelTeam}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>

                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>{" "}
                                 Team Order
                                </Button>
                              </div>
                            </div>
                          </>
                        )}                       
                        
                          {(homeData?.type == "banner" || homeData?.type == "create_challenge") && (

                                                    
                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Select Banner
                          </label>

                            <div
                            className="col-sm-6"
                            onClick={() => toggleBannerModal()}
                          >
                            
                            {bannerText ? (
                            
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#5e18c9",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleBannerModal()}
                                >
                                  Select Banner
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={bannerImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={bannerText}
                                  id="selectedVenue"
                                  placeholder="No Banner chosen"
                                />
                              </div>
                            ) : (
                              
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#5e18c9",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleBannerModal()}
                                >
                                  Select Banner
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No Banner chosen"
                                />
                              </div>
                            )}
                          </div>   
                          </div> 

                          )}
                       
                        {homeData?.type == "video" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Add Video
                            </label>
                           
                            <div className="col-lg-9 video-list">
                              <div className="row">
                                {uploadedVideo?.map((item, index) => (
                                  <div
                                    className="col-lg-4 g-3 card-video"
                                    key={item?._id || index}
                                  >
                                    <video width="100%" controls>
                                      <source
                                        src={item?.videoUrl || item?.video}
                                        type="video/mp4"
                                      />
                                    </video>
                                    {item.title}
                                    <div style={{ textAlign: "start" }}>
                                      <button
                                        type="button"
                                        onClick={() => handleRemoveVideo(index)}
                                        className="btn-remove"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  //  onClick={CustomVideoModal}
                                  onClick={() => setVideoMdl(true)}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>

                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>  Video Order
                                </Button>
                              </div>
                            </div>

                          </div>
                        )}                 
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  style={{ marginRight: "1rem" }}
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      </Container> 


        </div>


      {/* modal */}

 

      <TeamModal
        show={modelTeam}
        closeModal={closeModalT}
        selectRow={selectTeamRow}
      />
         <BannerModal
        show={bannerModal}
        closeModal={ () => setBannerModal(false)}
        selectRow={selectBannerRow}
      />    

   

      <VideoModal
        show={videoMdl}
        AddUploadedVideo={AddUploadedVideo}
        closeModal={closeVideoModal} 
      />
     
      <FileUpload message="File Uploading" status={startLoader} />

      <DragDropModal
        show={showOrder}
        data={dragDropData}
        setData={updateDragDropData}
        closeModal={() => setShowOrder(false)}
      />
    </React.Fragment>
  );
}
