import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import './styles.css'; // Import your custom styles if any

const UserIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6zm0 2c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"/>
    </svg>
);

const ActivityIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 12h-4.18l-1.9-4.27a1 1 0 00-1.82 0l-3.2 7.2-2.2-4.4a1 1 0 00-1.78 0L2 17h20l-3-5z"/>
  </svg>
  
);

const ChallengesIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 8V4H8v4H2v14h20V8h-6zM10 6h4v2h-4V6zM4 10h16v10H4V10z"/>
</svg>
  

  
);

const TeamIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V20h14v-3.5C17 14.17 12.33 13 10 13zm8 0c-.29 0-.62.02-.97.05.59.49 1.17 1.14 1.61 1.95.41.75.36 1.7-.18 2.32V20h6v-3.5c0-2.33-4.67-3.5-7-3.5z"/>
    </svg>

);

const defaultData = [
    {
        color: '#3498db', // Darker shade for Total Users
        icon: <UserIcon />, // Total Users
        label: 'Total Users',
    },
    {
        color: '#2ecc71', // Darker shade for Total Business
        icon: <ChallengesIcon />, // Total Business
        label: 'Total Challenges',
    },
    {
        color: '#f1c40f', // Darker shade for Total Venue
        icon: <ActivityIcon />, // Total Venue
        label: 'Total Activity',
    },
    {
        color: '#e67e22', // Darker shade for Total Offer
        icon: <TeamIcon />, // Total Offer
        label: 'Total Teams',
    },
  
];

const DashboardCard = ({ color, icon, value, label }) => {
    return (
        <Col md="3">
            <Card style={{ backgroundColor: color, borderRadius: '10px', textAlign: 'center' }}>
                <CardBody>
                    <div style={{ fontSize: '30px', marginBottom: '10px' }}>
                        {icon}
                    </div>
                    <h3 style={{ fontSize: '24px', marginBottom: '10px', color: '#212121' }}>{value}</h3>
                    <p style={{ fontSize: '14px', color: '#212121' }}>{label}</p>
                </CardBody>
            </Card>
        </Col>
    );
};

const Dashboard = ({ values = [] }) => {
    const data = defaultData.map((item, index) => ({
        ...item,
        value: values[index] !== undefined ? values[index] : item.value
    }));

    return (
        <Row>
            {data.map((item, index) => (
                <DashboardCard
                    key={index}
                    color={item.color}
                    icon={item.icon}
                    value={item.value}
                    label={item.label}
                />
            ))}
        </Row>
    );
};

export default Dashboard;
