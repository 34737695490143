 
import  ToolkitProvider   from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import Lightbox from "react-image-lightbox";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";

//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
  Container
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink,useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const filterObj = {
  title: "",
  status: "",
  createdAt: "",
};

const NotificationList = (props) => {
  const navigate = useNavigate();

  const [modal_center, setmodal_center] = useState(false);
  const [notificationdata, setnotificationdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [selectedItems, setselectedItems] = useState([]);
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  const [resetSearch, setResetSearch] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });
  const [model, setModel] = useState(false);

  useEffect(() => {
    notificationManagment();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage == 1) {
      notificationManagment();
      return;
    }
    setCurrentPage(1);
  }, [sort, sortOrder, sizePerPageOrg]);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const dataObj = {
      notificationId: actId,

      status: status,
    };

    const res = await putRequest("user/notification/update", dataObj);

    if (res.status == 1) {
      toast.success("Notification Status updated Successfully");
      notificationManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };
  const notificationManagmentSearch = async () => {
    if (currentPage == 1) {
      notificationManagment();
      return;
    }
    setCurrentPage(1);
  };

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (key === "title") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
   
    toggleModel();
    notificationManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    notificationManagment();
  };

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }
  function dateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.createdAt)}</div>;
  }
  function scheduledFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.scheduledDateTime)}</div>;
  }

  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function TypeFormatter(cellContent, row) {
    let contentToShow;

    switch (row.type) {
      case "venue":
        contentToShow = row.venueName;
        break;
      case "offer":
        contentToShow = row.offerName;
        break;
      case "category":
        contentToShow = row.categoryName;
        break;
      case "general":
        contentToShow = "General";
        break;
      case "link":
        contentToShow = (
          <a href={row.typeId} target="_blank" rel="noopener noreferrer">
            {row.typeId}
          </a>
        );
        break;
      default:
        contentToShow = "Unknown Type";
        break;
    }

    return <span>{contentToShow}</span>;
  }

  function readPercentageFormatter(cellContent, row) {
    return (
      <>
        {Math.floor(row.readPercentage)}%
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: Math.floor(row.readPercentage) + "%" }}
            aria-valuenow={Math.floor(row.readPercentage)}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </>
    );
  }

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = 0), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = 1), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.title}
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("status", e.target.value)}
          className="form-select"
          value={filter.status}
        >
          <option value="">Select Status</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button
    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
    },
    {
      dataField: "image",
      text: "Image",
      formatter: ImgCoverFormatter,
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
    },
    {
      dataField: "type",
      text: "Type Content",
      formatter: TypeFormatter,
    },
    {
      dataField: "targetUser",
      text: "Target User",
      sort: false,
    },
    // {
    //   dataField: "totalAudience",
    //   text: "Total Audience",
    //   sort: false,
    // },
    {
      dataField: "readPercentage",
      formatter: readPercentageFormatter,
      text: "Read %",
      sort: false,
    },
    // {
    //   dataField: "scheduledDateTime",
    //   text: "Scheduled at",
    //   formatter: scheduledFormatter,
    //   sort: false,
    // },
    {
      dataField: "status",
      text: "Status",
      formatter: activeFormatter,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      size: "120px",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    //setValue(event.target.value);
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    //setsort(event.target.value);
    setsort(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");
  };

  useEffect(() => {
    if (!resetSearch) return;
    if (currentPage == 1) {
      notificationManagment([], true);
      return;
    }
    setCurrentPage(1);
  }, [resetSearch]);

  const handleNavigate = row => {
    navigate(`/notificationdetails/${row._id}`, { state: { row } });
  };

  const handleNavigate2 = row => {
    navigate(`/Updatenotificationdata/${row._id}`, { state: { row } });
  };

  const handleNavigate3 = row => {
    navigate(`/notificationclone`, { state:  row  });
  };

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          <Tippy content="Detail">
            <button type="button" className=" btn btn-primary m-1" onClick={() => handleNavigate(row)}>
              {/* <Link
                to={{
                  pathname: `/notificationdetails/${row._id}`,
                  state: { row },
                }}
              >
                <i className="fal fa-eye fs-5 text-light"></i>
              </Link> */}
               <i className="fal fa-eye fs-5 text-light"></i>
            </button>
          </Tippy>
          <Tippy content="Edit">
            <button type="button" className="btn btn-info m-1" onClick={() => handleNavigate2(row)}>
              {/* {" "}
              <Link
                to={{
                  pathname: `/Updatenotificationdata/${row._id}`,
                  state: { row },
                }}
              >
                {" "}
                <i class="fal fa-pencil fs-5 text-light"></i>
              </Link>{" "} */}
              <i class="fal fa-pencil fs-5 text-light"></i>
            </button>
          </Tippy>
          <Tippy content="Clone">
            <button type="button" className=" btn btn-secondary m-1" onClick={() => handleNavigate3(row)}>
              {/* <Link
                to={{
                  pathname: `/notificationclone`,
                  state: { row },
                }}
              >
                <i className="fal fa-clone fs-5 text-light"></i>
              </Link> */}
              <i className="fal fa-clone fs-5 text-light"></i>
            </button>
          </Tippy>
          <Tippy content="Delete">
            <button
              type="button"
              className="btn btn-danger  m-1"
              onClick={() => handlerdelete(row._id)}
            >
              <i class="fal fa-trash  fs-5"></i>
            </button>
          </Tippy>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const notificationManagment = async (filterArgs = []) => {
    
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }
    try {
      const response = await postRequest(`user/notification/list/all`, payload);
      const data = response.data;
      if (resetSearch) {
        setResetSearch(false);
      }
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setnotificationdata(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteNotification(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteNotification = async (id) => {
    const response = await deleteRequest(`user/notification/delete`, {
      notificationId: id,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      notificationManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids); // Call the function to show the random word dialog
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleNotifications(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleNotifications = async (ids) => {
    const response = await deleteRequest(`user/notification/delete`, {
      ids: ids,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      notificationManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">

        <Container fluid>
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Notifications</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Notifications</li>:{" "}
                <li className="breadcrumb-item">Notification List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end mb-3">
            <Link to="/Createnotification">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={notificationdata}
                            columns={columns}
                          >
                            {(props) => (
                              <div className='row px-3 g-3'>
                                <div className="col-6 col-md-2 d-flex align-items-center">
                                  <label className="mb-0 text-label me-2">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    
                                  >
                                    <option value="10">10</option>

                                    <option value="20">20</option>

                                    <option value="50">50</option>
                                  </select>
                                </div>
                                <div className="col-6 col-md-2 d-flex align-items-center">
                                  <label className="mb-0 text-label me-2">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                   
                                  >
                                    <option value="title">Title</option>
                                    <option value="type">Type</option>
                                    <option value="type">Type Content</option>
                                    <option value="targetUser">
                                      Target User
                                    </option>
                                    <option value="scheduledDateTime">
                                      Scheduled At
                                    </option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                </div>
                                <div className="col-12 col-md-2 d-flex align-items-center">
                                  <label className="mb-0 text-label ms-2">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                </div>

                                <div className="col-12  offset-md-1 col-md-3  d-flex align-items-center  justify-content-start justify-content-md-end">
                                    
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control me-2"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                     
                                    />
                                    <button
                                      onClick={() => {
                                        notificationManagmentSearch();
                                      }}
                                      type="button"
                                      className="btn btn-primary me-2"
                                    >
                                      Search
                                    </button>
                                    </div>
                                    <div className="col-12 flex-wrap col-md-2 d-flex align-items-center">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger me-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger me-2"
                                    >
                                      Reset
                                    </button>

                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info "
                                    >
                                      Filter
                                    </button>
                                  </div>
                                

                                <Col className="col-lg-12 mt-3 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => (
                                      <div>No data available</div>
                                    )}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    // classes="table-custom-striped"
                                    classes="table-custom-border"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                                </div>
                              
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      </div>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default NotificationList;
