import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner,Container } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";


import FileUpload from "../../components/Common/FileUpload.js";

export default function CreateVideo() {
  const [videoData, setVideoData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [venueList, setVenueList] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [model, setModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  let history = useNavigate();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);


  const toggleModel = () => {
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const Createvideo = async (e) => {
    e.preventDefault();
    
    if (!videoData?.title) {
      toast.error("Title is required");
      return;
    }
    if (!videoData?.description) {
      toast.error("Please enter description");
      return;
    }
    if (!selectedImage) {
      toast.error("Please select video");
      return;
    }
    setStartLoader(true);
    setLoading(true);

    const formData = new FormData();   

    if(videoData){
      formData.append("title", videoData?.title  ||'');
      formData.append("description", videoData?.description || '');
    }
 
    // formData.append("title", videoData.title  ||'');
    // formData.append("description", videoData.description || '');
    formData.append("videoUrl", selectedImage);

    const response = await postRequestForm("home-block/video/create", formData);
    setStartLoader(false);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history(-1);
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...videoData };
    list[index] = value;
    setVideoData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">

     <Container>

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Video Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Video Management</li>:{" "}
              <li className="breadcrumb-item">Create Video</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history(-1)}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

     
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Video </h4>
                <Form onSubmit={Createvideo}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">                      

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              accept="video/*"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="videoUrl"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
    </div>

      {/* modal */}      
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
